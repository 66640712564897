@import "@mod-utwente_design/scss/config-colors.css";
:root {
  --zindex-modaldialog: 12;
  --zindex-popup: 11;
  --zindex-cobrowser: 10;
  --zindex-searchsheet: 9;
  --zindex-hamburger: 8;
  --zindex-menu: 7;
  --zindex-menumodal: 6;
  --zindex-messagesarea: 5;
  --zindex-navbar: 4;
  --zindex-above-deeplinks: 3;
  --zindex-deeplinks: 2;
  --zindex-main: 1;
  --zindex-header-cta: 6;
  --zindex-title: 5;
  --zindex-itc-swoosh: 4;
  --zindex-header: 2;
  --zindex-headerbg: -1;
  --breakpoint-flexgrid: 1430px;
  --breakpoint-menuhidden: 1279px;
  --breakpoint-deeplinks: 959px;
  --breakpoint-tablet: 749px;
  --breakpoint-phone: 480px;
  --menupart-translation: none;
  --menu-width: 260px;
  --menu-height: auto;
  --menu-transition-duration: 250ms;
  --menu-topmenu-display: none;
  --menu-topmenu-items-display: none;
  --menu-topmenu-title-border: none;
  --menu-padding: 40px;
  --menu-header-spacing: 0;
  --menu-indentation: 20px;
  --menu-item-height: 40px;
  --menu-item-transition-duration: 150ms;
  --contentpart-margin: var(--menu-width);
  --hamburger-display: none;
  --hamburger-top: 0px;
  --header-navbar-height: 0;
  --header-margin: 0;
  --header-padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
  --header-background-height: 260px;
  --header-grid-rows: [top] auto [header-top] 1fr [title-above] calc(var(--header-background-height) - 2 * var(--header-sliderdots-height)) [title-below] var(--header-sliderdots-height) [bottom];
  --header-spacing: 30px;
  --header-photos-width: 1600px;
  --header-photos-height: 640px;
  --header-photos-max-height: 640px;
  --header-photos-aspect-padding: 0;
  --header-videos-display: block;
  --header-videomobile-display: none;
  --header-videos-width: 1280px;
  --header-videos-height: 352px;
  --header-videos-aspect-padding: 0;
  --header-photos-fixed-height: 320px;
  --header-sliderdots-height: 45px;
  --header-sliderdots-display: flex;
  --header-slideshow-duration: 1s;
  --header-author-height: 16px;
  --header-author-bottom: calc((var(--header-sliderdots-height) - var(--header-author-height)) / 2);
  --header-author-right: calc((var(--header-sliderdots-height) - var(--header-author-height)) / 2);
  --header-filters-height: 100px;
  --header-filters-margin: 0;
  --header-title-margin: 0;
  --universe-rotation-duration: 180s;
  --notification-grid-area: grid-left / grid-right;
  --notification-cookiebar-separator: none;
  --notification-padding: 20px 64px 16px;
  --header-cta-width: 320px;
  --header-cta-padding-vert: 20px;
  --header-cta-padding-hor: 20px;
  --header-cta-flex-direction: column;
  --header-title-cta-padding: 0;
  --header-overlay-width: 125px;
  --header-overlay-position: absolute;
  --header-overlay-left: calc(((100% - var(--main-grid-width)) / 2) + 20px);
  --header-overlay-transform: translateY(calc(100% - 100px));
  --main-grid-padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
  --main-grid-column: 65px;
  --main-grid-gap: 30px;
  --main-content-width: calc(8 * var(--main-grid-column) + 7 * var(--main-grid-gap));
  --main-grid-width: calc(12 * var(--main-grid-column) + 11 * var(--main-grid-gap));
  --main-grid-margin: 1fr;
  /*
  [left] [grid-left] [text-left]                                 [text-right] [grid-right] [right]
    |        |           |                                               |           |        |
    | margin | 2 columns |                   8 columns                   | 2 columns | margin |
             |     |     |     |     |     |     |     |     |     |     |     |     |
             |                              12 columns                               |

    By default, all content columns have a fixed width and the margins have a width of 1fr. If the width is too small to
    contain the fixed-width columns, the columns get a width of 1fr and the margins are set to 0. There will always be at
    least a gutter-sized padding left and right.
  */
  --main-grid: [left] minmax(env(safe-area-inset-left), var(--main-grid-margin)) [grid-left] repeat(2, var(--main-grid-column)) [text-left] repeat(8, var(--main-grid-column)) [text-right] repeat(2, var(--main-grid-column)) [grid-right] minmax(env(safe-area-inset-right), var(--main-grid-margin)) [right];
  --main-8col-layout: text-left / text-right;
  --main-12col-layout: grid-left / grid-right;
  --main-full-layout: left / right;
  --main-embed-title-layout: grid-left / grid-right;
  --main-padding-bottom: 50px;
  --widget-equalheight-width: calc((var(--main-grid-width) - 2 * var(--main-grid-gap) - 1px) / 3);
  --newseventsrecent-news-columns: grid-left / span 8;
  --newseventsrecent-sidebar-columns: span 4 / grid-right;
  --newseventsrecent-sidebar-padding: 25px;
  --slideshow-height: 450px;
  --slideshow-flex-direction: row;
  --slideshow-panel-flex: 0 0 50%;
  --slideshow-panelleft-order: 1;
  --slideshow-panelleft-padding: 0 var(--main-grid-gap) 0 0;
  --slideshow-panelleft-width: auto;
  --slideshow-panelright-order: 2;
  --slideshow-panelright-height: auto;
  --banner-slide-width: var(--main-grid-width);
  --banner-slide-padding: 0 100px 0 0;
  --banner-overlay-width: var(--main-grid-width);
  --people-overiew-list-columns: grid-left / span 8;
  --footer-padding-right: env(safe-area-inset-right);
  --footer-padding-bottom: env(safe-area-inset-bottom);
  --footer-padding-left: env(safe-area-inset-left);
  --footer-prefooter-display: grid;
  --footer-prefooter-height: 300px;
  --footer-address-columns: grid-left / span 4;
  --footer-address-padding: 0px;
  --footer-address-width: auto;
  --footer-image-columns: span 9 / right;
  --footer-share-column: span 3 / grid-right;
  --footer-links-column-1: 2 / span 3;
  --footer-links-column-2: 5 / span 3;
  --footer-links-column-3: 8 / span 3;
  --footer-links-column-4: 11 / span 3;
  --footer-footerlinks-column: var(--main-12col-layout);
  --deeplinks-display: block;
  --deeplinks-width: 160px;
  --deeplinks-margin: 50px;
  --deeplinks-padding: 10px;
  --deeplinks-top: calc(var(--navbar-height) + var(--deeplinks-margin));
  --cta-font-size: 40px;
  --cta-line-height: 40px;
  --searsia-sheet-top: var(--navbar-height);
  --searsia-form-width: 920px;
  --searsia-form-margin: calc(50% - var(--searsia-form-width) / 2);
  --searsia-close-display: none;
  --searsia-close-color: var(--color-white);
  --searsia-close-background-color: var(--color-black);
}

html.ut--nonbranding {
  --searsia-sheet-top: 0px;
  --searsia-close-display: block;
  --searsia-close-color: var(--color-black);
  --searsia-close-background-color: var(--color-white);
}

:root {
  --navbar-position: sticky;
  --navbar-flex-direction: column;
  --navbar-top: 0;
  --navbar-height: 60px;
  --navbar-padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
  --navbar-background-color: var(--color-black);
  --navbar-color: var(--color-white);
  --navbar-menu-display: block;
  --navbar-menu-width: 240px;
  --navbar-menu-padding: 10px;
  --navbar-menu-item-height: 40px;
  --navbar-menu-item-padding: 20px;
  --navbar-menu-border-color: var(--color-grey-dark);
  --navbar-menu-border-padding: 0;
}

html.ut--nonbranding {
  --navbar-background-color: var(--color-grey-light);
  --navbar-color: var(--color-black);
  --navbar-menu-border-color: var(--color-grey-lines);
  --navbar-menu-border-padding: 10px;
}

.contentpart__navbar-crumbs {
  --breadcrumbs-padding: 0 30px;
  --breadcrumbs-color: var(--color-white);
  --breadcrumbs-display: block;
}
.contentpart__navbar-crumbs .contentpart__breadcrumblist {
  --breadcrumbs-display: flex;
}
html.ut--nonbranding .contentpart__navbar-crumbs {
  --breadcrumbs-color: var(--color-black);
}

.contentpart__header-crumbs {
  --breadcrumbs-padding: 10px calc(20px + env(safe-area-inset-right)) 10px calc(20px + env(safe-area-inset-left));
  --breadcrumbs-color: var(--color-black);
  --breadcrumbs-display: none;
}
.contentpart__header-crumbs .contentpart__breadcrumblist {
  --breadcrumbs-display: flex;
}

.menupart {
  --logo-display: flex;
  --identity-display: block;
  --identity-margin: 0;
  --identity-titles-margin: 0 20px 2px 0;
  --identity-titles-padding: 30px env(safe-area-inset-right) 10px calc(20px + env(safe-area-inset-left));
  --identity-titles-lineheight: 22px;
  --identity-branding-logo-margin-hor: calc(20px + max(env(safe-area-inset-left), env(safe-area-inset-right)));
  --identity-branding-logo-margin-vert: 20px;
  --identity-branding-partner-navbar-height: 40px;
  --identity-itc-logo-width: 52px;
  --identity-itc-logo-top: 0px;
  /* Tweak some settings for ITC */
  /* Tweak some settings for non-branding logo sites */
}
.menupart .identitypart__site--itc {
  --identity-titles-padding: 15px calc(25px + env(safe-area-inset-right)) 0 calc(25px + env(safe-area-inset-left));
}
.menupart .identitypart__site--branding {
  --identity-titles-margin: 0;
  --identity-titles-padding: var(--identity-branding-logo-margin-vert) var(--identity-branding-logo-margin-hor) 0;
}

.contentpart {
  --logo-display: none;
  --identity-display: none;
  --identity-margin: calc(var(--navbar-height) + env(safe-area-inset-left));
  --identity-titles-margin: 0 20px 2px 0;
  --identity-titles-padding: 0 env(safe-area-inset-right) calc(((var(--navbar-height) - var(--identity-titles-lineheight)) / 2) - 2px) 20px;
  --identity-titles-lineheight: 22px;
  --identity-branding-logo-margin-hor: 20px;
  --identity-branding-logo-margin-vert: 20px;
  --identity-branding-partner-navbar-height: var(--navbar-height);
  --identity-itc-logo-width: 52px;
  --identity-itc-logo-top: -11px;
  /* Tweak some settings for ITC */
  /* Tweak some settings for non-branding logo sites */
}
.contentpart .identitypart__site--itc {
  --identity-titles-padding: 0 20px;
}
.contentpart .identitypart__site--branding {
  --identity-titles-margin: 0;
  --identity-titles-padding: 0 var(--identity-branding-logo-margin-hor) var(--identity-branding-logo-margin-vert);
}

html.site--itc {
  --header-sliderdots-height: 90px;
  --header-author-bottom: 0;
  --header-author-right: 0;
  --header-filters-margin: 60px; /* push content at the bottom of the page header upwards so it's not fully on the ITC swoosh */
}

.cta__button__title--default {
  --header-cta-button-display: inline;
}

.cta__button__title--mobile {
  --header-cta-button-display: none;
}

html.ws2016--iframed {
  --menu-width: 0px;
}

@media (max-width: 1820px) {
  :root {
    /* Scale header photo/video proportionally */
    --header-photos-width: auto;
    --header-photos-height: auto;
    --header-photos-aspect-padding: calc(320 / 750 * 100%);
    --header-videos-aspect-padding: calc(320 / 750 * 100%);
  }
}
@media (max-width: 1690px) {
  :root {
    /* Update header title CTA padding */
    --header-title-cta-padding: 130px;
  }
}
@media (max-width: 1490px) {
  :root {
    /* Prevent title from sticking to left side of screen */
    --header-title-margin: calc((var(--main-grid-width) + 2 * var(--main-grid-gap) - (100vw - var(--menu-width) - 2 * var(--header-spacing))) / 2);
  }
}
@media (max-width: 1430px) {
  :root {
    /* Make the content columns flexible, hide the margins left and right */
    --main-grid-column: 1fr;
    --main-grid-margin: 0px;
    --main-content-width: calc((100vw - var(--menu-width) - 13 * var(--main-grid-gap)) * 8 / 12 + 7 * var(--main-grid-gap));
    --main-grid-width: calc(100vw - var(--menu-width) - 2 * var(--main-grid-gap));
    /* Prevent title from sticking to left side of screen */
    --header-title-margin: var(--header-spacing);
    /* Update header title CTA padding */
    --header-title-cta-padding: 190px;
  }
}
/*
Options for having the option to hide the menu by default:
1. we dont use a media query (@media (max-width: 1279px)) but set a class
   (using the same code which uses the --breakpoint-menuhidden to open/close the menu based on the viewport size)
   - If the JS code fails, the layout will be messed up
2. use a mixins and publish the overrides in both the media query AND
3. have JS find this media query in the sheet and update it from 1279px to a very high number if .ut--menuhiddenbydefault is set
*/
@media (max-width: 1279px) {
  :root {
    /* Make room for the navbar */
    --header-margin: var(--header-navbar-height);
    /* Remove spacing around header photo */
    --header-spacing: 0px;
    /* Reposition the menu */
    --menupart-translation: translateX(calc(0px - var(--menu-width)));
    --menu-height: 100vh;
    --menu-header-spacing: calc(var(--navbar-height) + 1px);
    --contentpart-margin: 0;
    /* Make notification area take up all width, add separator between cookiebar and notification */
    --notification-grid-area: left / right;
    --notification-cookiebar-separator: 1px solid var(--color-grey-lines);
    /* Scroll navbar with site */
    --navbar-position: absolute;
    /* Show the hamburger and menu top menu title */
    --hamburger-display: block;
    --menu-topmenu-display: flex;
    /* Update header title CTA padding */
    --header-title-cta-padding: 165px;
    /* Remove extra space between photo edge and overlay */
    --header-overlay-left: calc(((100% - var(--main-grid-width)) / 2));
    /* Make the content columns static again */
    --main-grid-column: 65px;
    --main-grid-margin: 1fr;
    --main-content-width: calc(8 * var(--main-grid-column) + 7 * var(--main-grid-gap));
    --main-grid-width: calc(12 * var(--main-grid-column) + 11 * var(--main-grid-gap));
    /* Update deeplinks top position */
    --deeplinks-top: var(--deeplinks-margin);
  }
  /* Make room for site title bar in navbar */
  html.utwente--sitetitlebar {
    --hamburger-top: var(--navbar-height);
  }
  /* Author shouldn't stick to the right side of the screen */
  html.site--itc {
    --header-author-right: 30px;
  }
  /* Navbar and site title are combined */
  html.ut--nonbranding {
    --navbar-flex-direction: row-reverse;
    --navbar-background-color: var(--color-white);
    --hamburger-top: 0;
  }
  /* Hide the menu logo and site titles, show the navbar logo and site titles */
  /* Hide the menu site titles, show the navbar site titles */
  .menupart {
    --logo-display: none;
    --identity-display: none;
  }
  .contentpart {
    --logo-display: flex;
    --identity-display: flex;
  }
}
html.ut--menuhiddenbydefault {
  --menupart-translation: translateX(calc(0px - var(--menu-width))) !important;
  --hamburger-display: block;
  /* Make room for site title bar in navbar */
  /* Navbar and site title are combined */
}
html.ut--menuhiddenbydefault.utwente--sitetitlebar {
  --hamburger-top: var(--navbar-height);
}
html.ut--menuhiddenbydefault.ut--nonbranding {
  --hamburger-top: 0;
}

html.ut--menuhiddenbydefault.utwente--menuhidden {
  /* Make room for site title bar in navbar */
  /* Author shouldn't stick to the right side of the screen */
  /* Navbar and site title are combined */
  /* Hide the menu logo and site titles, show the navbar logo and site titles */
  /* Hide the menu site titles, show the navbar site titles */
  --breakpoint-menuhidden: 13371337px; /* Set Javascript trigger for showing menu very high */
  --contentpart-margin: 0;
}
html.ut--menuhiddenbydefault.utwente--menuhidden :root {
  /* Make room for the navbar */
  --header-margin: var(--header-navbar-height);
  /* Remove spacing around header photo */
  --header-spacing: 0px;
  /* Reposition the menu */
  --menupart-translation: translateX(calc(0px - var(--menu-width)));
  --menu-height: 100vh;
  --menu-header-spacing: calc(var(--navbar-height) + 1px);
  --contentpart-margin: 0;
  /* Make notification area take up all width, add separator between cookiebar and notification */
  --notification-grid-area: left / right;
  --notification-cookiebar-separator: 1px solid var(--color-grey-lines);
  /* Scroll navbar with site */
  --navbar-position: absolute;
  /* Show the hamburger and menu top menu title */
  --hamburger-display: block;
  --menu-topmenu-display: flex;
  /* Update header title CTA padding */
  --header-title-cta-padding: 165px;
  /* Remove extra space between photo edge and overlay */
  --header-overlay-left: calc(((100% - var(--main-grid-width)) / 2));
  /* Make the content columns static again */
  --main-grid-column: 65px;
  --main-grid-margin: 1fr;
  --main-content-width: calc(8 * var(--main-grid-column) + 7 * var(--main-grid-gap));
  --main-grid-width: calc(12 * var(--main-grid-column) + 11 * var(--main-grid-gap));
  /* Update deeplinks top position */
  --deeplinks-top: var(--deeplinks-margin);
}
html.ut--menuhiddenbydefault.utwente--menuhidden html.utwente--sitetitlebar {
  --hamburger-top: var(--navbar-height);
}
html.ut--menuhiddenbydefault.utwente--menuhidden html.site--itc {
  --header-author-right: 30px;
}
html.ut--menuhiddenbydefault.utwente--menuhidden html.ut--nonbranding {
  --navbar-flex-direction: row-reverse;
  --navbar-background-color: var(--color-white);
  --hamburger-top: 0;
}
html.ut--menuhiddenbydefault.utwente--menuhidden .menupart {
  --logo-display: none;
  --identity-display: none;
}
html.ut--menuhiddenbydefault.utwente--menuhidden .contentpart {
  --logo-display: flex;
  --identity-display: flex;
}

@media (max-width: 1170px) {
  :root {
    /* Make the content columns flexible, hide the margins left and right */
    --main-grid-column: 1fr;
    --main-grid-margin: 0px;
    --main-content-width: calc((100vw - 13 * var(--main-grid-gap)) * 8 / 12 + 7 * var(--main-grid-gap));
    --main-grid-width: calc(100vw - 2 * var(--main-grid-gap));
  }
}
@media (max-width: 959px) {
  :root {
    /* Update header title CTA padding */
    --header-title-cta-padding: 200px;
    /* Hide homepage video */
    --header-videos-display: none;
    --header-videomobile-display: block;
    /* Position the overlay statically below the CTA */
    --header-overlay-position: static;
    --header-overlay-width: 90px;
    --header-overlay-transform: none;
    /* Display columns stacked instead of next to each other */
    --newseventsrecent-news-columns: grid-left / grid-right;
    --newseventsrecent-sidebar-columns: grid-left / grid-right;
    /* Update footer prefooter layout*/
    --footer-prefooter-display: flex;
    --footer-prefooter-height: 250px;
    --footer-address-padding: var(--main-grid-gap);
    --footer-address-width: 33.3333333333333333%;
    /* Footer links are made foldable */
    --footer-links-column-1: grid-left / grid-right;
    --footer-links-column-2: grid-left / grid-right;
    --footer-links-column-3: grid-left / grid-right;
    --footer-links-column-4: grid-left / grid-right;
    /* Hide the deeplinks */
    --deeplinks-display: none;
    /* Reduce full-width embedded component title width */
    --main-embed-title-layout: text-left / text-right;
    /* Resize widgets to fit 2 on a row instead of 3 */
    --widget-equalheight-width: calc((var(--main-grid-width) - var(--main-grid-gap) - 1px) / 2);
    /* Adjust search form width */
    --searsia-form-margin: 90px;
    --searsia-form-width: calc(100vw - 2 * var(--searsia-form-margin));
  }
  /* Hide the navbar breadcrumbs, show the header breadcrumbs */
  .contentpart__navbar-crumbs .contentpart__breadcrumblist {
    --breadcrumbs-display: none;
  }
  .contentpart__header-crumbs {
    --breadcrumbs-display: block;
  }
  .contentpart__header-crumbs + .contentpart__header {
    --header-margin: 0;
  }
  html.site--itc {
    --header-sliderdots-height: 60px;
  }
}
@media (max-width: 749px) {
  :root {
    /* Make the main content 10 columns wide */
    --main-grid: [left] minmax(env(safe-area-inset-left), var(--main-grid-margin)) [grid-left] var(--main-grid-column) [text-left] repeat(10, var(--main-grid-column)) [text-right] var(--main-grid-column) [grid-right] minmax(env(safe-area-inset-right), var(--main-grid-margin)) [right];
    --main-content-width: calc((100vw - 13 * var(--main-grid-gap)) * 10 / 12 + 9 * var(--main-grid-gap));
    /* Hide the navbar menu, show the menu top menu items*/
    --navbar-menu-display: none;
    --menu-topmenu-items-display: block;
    --menu-topmenu-title-border: 1px solid var(--color-grey-lines);
    /* Crop header photo/video horizontally instead of scaling proportionally */
    --header-photos-height: var(--header-photos-fixed-height);
    --header-photos-aspect-padding: 0;
    --header-videos-aspect-padding: 0;
    /* Make the CTA text smaller */
    --cta-font-size: 26px;
    --cta-line-height: 26px;
    /* Position header CTA on full top row, reduce padding, place button next to title */
    --header-cta-width: 100%;
    --header-cta-padding-vert: 15px;
    --header-cta-flex-direction: row;
    --header-cta-button-margin: 0px;
    --header-title-cta-padding: 0;
    /* Change slideshow layout */
    --slideshow-height: auto;
    --slideshow-flex-direction: column;
    --slideshow-panel-flex: 1 0 auto;
    --slideshow-panelleft-order: 2;
    --slideshow-panelleft-padding: 0 var(--main-grid-gap) 40px 0;
    --slideshow-panelleft-width: calc(100vw - var(--main-grid-gap));
    --slideshow-panelright-order: 1;
    --slideshow-panelright-height: 320px;
    /* Make sidebar full width */
    --newseventsrecent-sidebar-columns: left / right;
    --newseventsrecent-sidebar-padding: var(--main-grid-gap);
    /* Change banner layout */
    --banner-slide-width: 100%;
    --banner-slide-padding: 25px var(--main-grid-gap) 65px var(--main-grid-gap);
    /* Make people folder list wider */
    --people-overiew-list-columns: grid-left / span 10;
    /* Update the prefooter layout again */
    --footer-prefooter-display: grid;
    --footer-prefooter-height: auto;
    --footer-address-columns: var(--main-8col-layout);
    --footer-address-padding: 0;
    --footer-image-columns: left / right;
    /* Share page takes up full width */
    --footer-share-column: grid-left / grid-right;
    /* Update footer prefooter layout*/
    --footer-address-width: 100%;
    /* Resize widgets to fit within the content width instead of the grid width */
    --widget-equalheight-width: calc((var(--main-content-width) - var(--main-grid-gap) - 1px) / 2);
    /* Search sheet takes up the whole page */
    --searsia-sheet-top: 0px;
    --searsia-close-display: block;
    /* Adjust search form width */
    --searsia-form-margin: 28px;
    --searsia-form-width: calc(100vw - 2 * var(--searsia-form-margin) - var(--navbar-height));
  }
  :root .header--hascta {
    --header-grid-rows: [top] auto [header-top] 1fr [title-above] calc(var(--header-background-height) - 2 * var(--header-sliderdots-height)) [title-below] var(--header-sliderdots-height) [bottom];
  }
}
@media (max-width: 480px) {
  :root {
    /* Reduce gutter */
    --main-grid-gap: 20px;
    /* Make the grid 6 columns wide */
    --main-grid: [left] minmax(env(safe-area-inset-left), var(--main-grid-margin)) [grid-left text-left] repeat(6, var(--main-grid-column)) [text-right grid-right] minmax(env(safe-area-inset-right), var(--main-grid-margin)) [right];
    --main-content-width: calc(100vw - 2 * var(--main-grid-gap));
    /* Hide slider dots on smaller displays */
    --header-sliderdots-display: none;
    /* Position notification contents below icons */
    --notification-padding: 56px 20px 16px;
    /* Make people folder list wider */
    --people-overiew-list-columns: grid-left / grid-right;
    /* Give footer links some margin */
    --footer-share-column: text-left / text-right;
    --footer-links-column-1: text-left / text-right;
    --footer-links-column-2: text-left / text-right;
    --footer-links-column-3: text-left / text-right;
    --footer-links-column-4: text-left / text-right;
    --footer-footerlinks-column: text-left / text-right;
    /* Make the CTA text smaller */
    --cta-font-size: 18px;
    --cta-line-height: 20px;
    /* Reduce header CTA padding */
    --header-cta-padding-vert: 10px;
    --header-cta-padding-hor: 15px;
  }
  /* Show mobile CTA button instead of desktop button, if it's available */
  .cta__button__title--mobile + .cta__button__title--default {
    --header-cta-button-display: none;
  }
  .cta__button__title--mobile {
    --header-cta-button-display: inline;
  }
}
/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
/** @short Position the element within the text area of the grid (8 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to 8-column text width
@include grid-text-width();

// Constrain to 8-column text width, but update margins (note the $ prefix!)
@include grid-text-width($margin-left: 50px, $margin-right: 50px);
*/
/** @short Position the element within the full grid area (12 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to 12-column grid width
@include grid-grid-width();

// Constrain to 12-column grid width, but update margins (note the $ prefix!)
@include grid-grid-width($margin-left: 50px, $margin-right: 50px);
*/
/** @short Position the element within the full page width (12 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to full page width
@include grid-full-width();

// Constrain to full page width, but update margins and width (note the $ prefix!)
@include grid-full-width($width: 500px, $margin-left: auto, $margin-right: auto);
*/
/** @short Apply this to the current slide in a slideshow with fading effect
*/
/** @short Apply this to the next slide to show in a slideshow with fading effect
*/
/** @short Apply this to the current slide that's being hidden in a slideshow with fading effect
*/
/** @short Styling of a header slideshow slider dot
*/
/** @short Styling of the active header slideshow slider dot
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/** @short reset a button to be a like a normal element
*/
html.utwente--ws2021 {
  --pageside-padding: 0px;
  background: var(--color-white);
  /* When using flex:
     - our skiplinks button would be stretched unless we nested it
     - if we nested the skiplinks button we couldn't use sticky
       because a sticky needs to be in the parent it needs to stick within.
     - within a grid however we can still affect positioning and whether to stretch or shrink-wrap
  */
  /*
  Because the ws2016 styling uses 3 classes for specificity, we also have to specify 3 classes (we already have the extra
  .utwente--ws2021 class
  */
}
html.utwente--ws2021.wh-widgetpreview {
  max-width: var(--main-content-width);
}
html.utwente--ws2021.ut--ctapreview {
  background: var(--color-white);
}
html.utwente--ws2021 body {
  display: grid;
  grid-template-columns: 100%; /* .menupart and .contentpart */
  grid-template-rows: 100%;
  height: auto;
  min-height: 100%;
}
html.utwente--ws2021 body > * {
  grid-column: 1;
  grid-row: 1;
}
html.utwente--ws2021 .skiplinksbutton {
  align-self: start;
  justify-self: start;
}
html.utwente--ws2021.wh-widgetpreview {
  max-width: var(--main-content-width);
}
html.utwente--ws2021.wh-widgetpreview body {
  height: auto;
  min-height: 0;
  padding: 15px;
}
html.utwente--ws2021.wh-widgetpreview .utwente-cookiebar,
html.utwente--ws2021.wh-widgetpreview .usabilla_live_button_container {
  display: none;
}
html.utwente--ws2021.ws2016--iframed .menupart,
html.utwente--ws2021.ws2016--iframed .contentpart__navbar-container,
html.utwente--ws2021.ws2016--iframed .contentpart__header,
html.utwente--ws2021.ws2016--iframed .contentpart__header-crumbs,
html.utwente--ws2021.ws2016--iframed .footer__prefooter,
html.utwente--ws2021.ws2016--iframed .footer__image,
html.utwente--ws2021.ws2016--iframed .footer__links,
html.utwente--ws2021.ws2016--iframed .footer__footer {
  display: none;
}
html.utwente--ws2021 .themecolor--bg,
html.utwente--ws2021 .ut-querymatch::before {
  background-color: var(--theme-color);
}
html.utwente--ws2021 .themecolor--fg {
  color: var(--theme-color);
}
html.utwente--ws2021 .themecolor--br {
  border-color: var(--theme-color);
}
html.utwente--ws2021 .cta-themecolor--bg {
  background-color: var(--theme-color-cta);
}
html.utwente--ws2021 .cta-themecolor--fg {
  color: var(--theme-color-cta);
}
html.utwente--ws2021 .cta-themecolor--br {
  border-color: var(--theme-color-cta);
}
html.utwente--ws2021 .utwente-cookiebar {
  margin-bottom: var(--header-spacing);
  position: static;
}
html.utwente--ws2021 .notification__container {
  padding-bottom: var(--header-spacing);
  width: 100%;
}
html.utwente--ws2021 .notification__container--animate {
  transition: height var(--menu-transition-duration), opacity var(--menu-transition-duration) 100ms;
}
html.utwente--ws2021 .notification__container--animate {
  transition: height var(--menu-transition-duration) 100ms, opacity var(--menu-transition-duration);
}
html.utwente--ws2021 .notification__container .notification {
  border: none;
  padding: var(--notification-padding);
  position: relative;
}
html.utwente--ws2021 .notification__container .notification.cta--show {
  display: block;
}
html.utwente--ws2021 .notification__container .notification__icon {
  left: 20px;
  margin: 0;
  position: absolute;
  top: 18px;
}
html.utwente--ws2021 .notification__container .notification__textholder,
html.utwente--ws2021 .notification__container .notification__title,
html.utwente--ws2021 .notification__container .notification__buttonholder {
  display: inline;
  margin: 0;
  padding: 0;
}
html.utwente--ws2021 .notification__container .notification__buttonholder::before {
  content: " ";
}
html.utwente--ws2021 .notification__container .notification__close {
  margin: 0;
  position: absolute;
  right: 16px;
  top: 14px;
}
html.utwente--ws2021 .utwente-cookiebar + .notification__container {
  border-top: var(--notification-cookiebar-separator);
}
html.utwente--ws2021 .headercta {
  flex: none;
  margin-right: env(safe-area-inset-right);
  padding-bottom: var(--header-cta-padding-vert);
  pointer-events: auto;
  position: relative;
  width: var(--header-cta-width);
}
html.utwente--ws2021 .headercta::after {
  background: var(--theme-color-cta);
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  opacity: 0.9;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
html.utwente--ws2021 .headercta .cta__contents {
  display: flex;
  flex-direction: var(--header-cta-flex-direction);
  flex-wrap: wrap;
  width: 100%;
}
html.utwente--ws2021 .headercta .cta__textholder {
  align-items: center;
  display: flex;
  flex: 1;
  margin: var(--header-cta-padding-vert) var(--header-cta-padding-hor) 0;
  max-width: calc(100% - 2 * var(--header-cta-padding-hor));
}
html.utwente--ws2021 .headercta .cta__title {
  color: var(--color-white);
  font-size: var(--cta-font-size);
  font-weight: var(--weight-light-condensed);
  line-height: var(--cta-line-height);
  text-transform: uppercase;
}
html.utwente--ws2021 .headercta .cta__title b {
  font-weight: var(--weight-bold-condensed);
}
html.utwente--ws2021 .headercta .cta__buttonholder {
  align-items: center;
  display: flex;
  flex: none;
  margin: var(--header-cta-padding-vert) var(--header-cta-padding-hor) 0;
}
html.utwente--ws2021 .headercta .cta__button {
  background: var(--color-white);
  color: var(--theme-color-cta);
}
html.utwente--ws2021 .headercta .cta__button:hover {
  color: var(--theme-color-cta-hover);
}
html.utwente--ws2021 .headercta .cta__button__title {
  /* For Chrome 117 to correctly apply the text color when the color uses an animated CSS variable,
     we need to use color: inherit; (or set the animated color directly on this element)
     Otherwise it'll use/inherit the color set on <html>.
  */
  color: inherit;
  display: var(--header-cta-button-display);
}
html.utwente--ws2021 .header--content8col .header__titleholder__contents > *:not(.filteredoverview__filterholder),
html.utwente--ws2021 .header--content8col .filteredoverview__toggleandsearchbar,
html.utwente--ws2021 .header--content8col .filters__expandedcontent__content,
html.utwente--ws2021 .header--content8col .filteredoverview__form {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-8col-layout);
}
html.utwente--ws2021 .header--content12col .header__titleholder__contents > *:not(.filteredoverview__filterholder),
html.utwente--ws2021 .header--content12col .filteredoverview__toggleandsearchbar,
html.utwente--ws2021 .header--content12col .filters__expandedcontent__content,
html.utwente--ws2021 .header--content12col .filteredoverview__form {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-12col-layout);
}
html.utwente--ws2021 .filteredoverview__form {
  grid-row: title-below/bottom;
  margin-bottom: var(--header-filters-margin);
  overflow: hidden;
  position: relative;
  z-index: var(--zindex-title);
}
html.utwente--ws2021 .filters__expandedcontent {
  background: transparent;
}
html.utwente--ws2021 .filteredoverview__feedbackandviewbar {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-8col-layout);
}
html.utwente--ws2021 .filteredoverview__feedbackandviewbar--is12col, html.utwente--ws2021 .filteredoverview__feedbackandviewbar--is12col ~ .filteredoverview__results {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-12col-layout);
}
html.utwente--ws2021 .filteredoverview__results {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-8col-layout);
}
html.utwente--ws2021.page--studyfinder .filteredoverview__results {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-full-layout);
  display: grid;
  width: 100%;
  column-gap: var(--main-grid-gap);
  grid-column-gap: var(--main-grid-gap);
  grid-template-columns: var(--main-grid);
}
html.utwente--ws2021.page--studyfinder .filteredoverview__results .filteredoverview__noresultsmessage {
  display: grid;
  width: 100%;
  column-gap: var(--main-grid-gap);
  grid-column-gap: var(--main-grid-gap);
  grid-template-columns: var(--main-grid);
  margin-left: unset;
  margin-right: unset;
  padding: 0;
}
html.utwente--ws2021.page--studyfinder .filteredoverview__results .filteredoverview__noresultsmessage > * {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-12col-layout);
}
html.utwente--ws2021 .studyfinderprogrammes--listview, html.utwente--ws2021.page--itcmajorprojectsoverview .filteredoverview__feedbackandviewbar, html.utwente--ws2021.page--itcmajorprojectsoverview .filteredoverview__results {
  grid-column: var(--main-12col-layout);
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}
html.utwente--ws2021 .studyfinderprogrammes .filteredoverview__noresultsmessage {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-full-layout);
}
html.utwente--ws2021 .studyfinderprogrammes--detailview {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-full-layout);
}
html.utwente--ws2021 .studyfinderprogrammes--detailview .studyfinder__programme {
  display: grid;
  width: 100%;
  column-gap: var(--main-grid-gap);
  grid-column-gap: var(--main-grid-gap);
  grid-template-columns: var(--main-grid);
}
html.utwente--ws2021 .studyfinderprogrammes--detailview .programme__contentwrapper {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-12col-layout);
  padding: 0;
}
html.utwente--ws2021 .studyfinderprogrammes--detailview .programme_specializations {
  display: grid;
  width: 100%;
  column-gap: var(--main-grid-gap);
  grid-column-gap: var(--main-grid-gap);
  grid-template-columns: var(--main-grid);
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-12col-layout);
}
html.utwente--ws2021 .studyfinderprogrammes--detailview .programme_specializations__content {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-12col-layout);
  padding: 0 0 25px;
}
html.utwente--ws2021 .main__deeplinks {
  background: var(--color-white);
  margin-bottom: var(--deeplinks-margin);
  margin-top: var(--deeplinks-margin);
  max-height: calc(100vh - 2 * var(--deeplinks-margin));
  opacity: 0.95;
  overflow: auto;
  padding: var(--deeplinks-padding);
  pointer-events: auto;
  position: -webkit-sticky;
  position: sticky;
  top: var(--deeplinks-top);
  transition: opacity var(--menu-transition-duration);
  width: var(--deeplinks-width);
  z-index: auto;
}
html.utwente--ws2021 .main__deeplinks:hover {
  opacity: 1;
}
html.utwente--ws2021 .foldabledeeplink {
  display: grid;
  width: 100%;
  column-gap: var(--main-grid-gap);
  grid-column-gap: var(--main-grid-gap);
  grid-template-columns: var(--main-grid);
  grid-column: var(--main-full-layout) !important;
}
html.utwente--ws2021 .foldabledeeplink .foldabledeeplink__title::after {
  transition: color 300ms;
}
html.utwente--ws2021 .foldabledeeplink .foldabledeeplink__title:hover::after, html.utwente--ws2021 .foldabledeeplink--open .foldabledeeplink__title::after {
  color: var(--color-black);
}
html.utwente--ws2021 .foldabledeeplink__title {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-8col-layout);
  padding-right: 36px; /* keep space for the expand/collapse icon to appear */
}
html.utwente--ws2021 .foldabledeeplink--active .foldabledeeplink__title {
  margin-bottom: 0;
}
html.utwente--ws2021 .foldabledeeplink__foldcontainer {
  grid-column: var(--main-full-layout);
}
html.utwente--ws2021 .foldabledeeplink__text {
  display: grid;
  width: 100%;
  column-gap: var(--main-grid-gap);
  grid-column-gap: var(--main-grid-gap);
  grid-template-columns: var(--main-grid);
}
html.utwente--ws2021 .foldabledeeplink__text > * {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-8col-layout);
}
html.utwente--ws2021 .main__contents--default {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-8col-layout);
  padding-left: unset;
  padding-right: unset;
}
html.utwente--ws2021 .contentpart__main > .main__contents--12col {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-12col-layout);
}
html.utwente--ws2021 .main__contents--fullwidth,
html.utwente--ws2021 .main__contents--fullwidth-nopadding,
html.utwente--ws2021 .contentpart__main > .main__contents--fullwidth {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-full-layout);
}
html.utwente--ws2021 .main__contents--fullwidth.main__contents--content8col,
html.utwente--ws2021 .main__contents--fullwidth-nopadding.main__contents--content8col {
  display: grid;
  width: 100%;
  column-gap: var(--main-grid-gap);
  grid-column-gap: var(--main-grid-gap);
  grid-template-columns: var(--main-grid);
}
html.utwente--ws2021 .main__contents--fullwidth.main__contents--content8col > *:not(.main__contents--fullwidth):not(.main__contents--fullwidth-nopadding),
html.utwente--ws2021 .main__contents--fullwidth-nopadding.main__contents--content8col > *:not(.main__contents--fullwidth):not(.main__contents--fullwidth-nopadding) {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-8col-layout);
}
html.utwente--ws2021 .main__contents--fullwidth.main__contents--content12col,
html.utwente--ws2021 .main__contents--fullwidth-nopadding.main__contents--content12col {
  display: grid;
  width: 100%;
  column-gap: var(--main-grid-gap);
  grid-column-gap: var(--main-grid-gap);
  grid-template-columns: var(--main-grid);
}
html.utwente--ws2021 .main__contents--fullwidth.main__contents--content12col > *:not(.main__contents--fullwidth):not(.main__contents--fullwidth-nopadding),
html.utwente--ws2021 .main__contents--fullwidth-nopadding.main__contents--content12col > *:not(.main__contents--fullwidth):not(.main__contents--fullwidth-nopadding) {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-12col-layout);
}
html.utwente--ws2021 .main__contents--overdeeplinks {
  z-index: var(--zindex-above-deeplinks);
}
html.utwente--ws2021 .widgetsblock {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-full-layout);
  margin-bottom: 0;
  margin-top: 50px;
}
html.utwente--ws2021 .widgetsblock:not(.widgetsblock--fullwidth) {
  display: grid;
  width: 100%;
  column-gap: var(--main-grid-gap);
  grid-column-gap: var(--main-grid-gap);
  grid-template-columns: var(--main-grid);
}
@media (max-width: 1429px) {
  html.utwente--ws2021 .widgetsblock.widgetsblock--fullwidth {
    display: grid;
    width: 100%;
    column-gap: var(--main-grid-gap);
    grid-column-gap: var(--main-grid-gap);
    grid-template-columns: var(--main-grid);
  }
}
html.utwente--ws2021 .widgetsblock.widgetsblock .widgetsholder__titleholder, html.utwente--ws2021 .widgetsblock.widgetsblock .widgetsblock__contents {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-12col-layout);
  max-width: none;
}
@media (max-width: 749px) {
  html.utwente--ws2021 .widgetsblock.widgetsblock .widgetsholder__titleholder, html.utwente--ws2021 .widgetsblock.widgetsblock .widgetsblock__contents {
    margin-left: unset;
    margin-right: unset;
    width: unset;
    grid-column: var(--main-8col-layout);
  }
}
html.utwente--ws2021 .widgetsblock.widgetsblock .widgetsblock__contents {
  column-gap: var(--main-grid-gap);
  row-gap: var(--main-grid-gap);
}
html.utwente--ws2021 .widgetsblock.widgetsblock .widgetsblock__contents .widget, html.utwente--ws2021 .widgetsblock.widgetsblock .widgetsblock__contents--greybg .widget, html.utwente--ws2021 .widgetsblock.widgetsblock .widgetsblock__contents--equalheight .widget {
  margin: 0;
}
html.utwente--ws2021 .widgetsblock.widgetsblock .widgetsblock__contents--equalheight {
  margin: 0;
}
html.utwente--ws2021 .widgetsblock.widgetsblock .widgetsblock__contents--equalheight .widget {
  width: var(--widget-equalheight-width);
}
@media (min-width: 1430px) {
  html.utwente--ws2021 .widgetsblock.widgetsblock--fullwidth .widgetsblock__contents {
    margin-left: auto;
    margin-right: auto;
    width: 1110px;
  }
}
@media (min-width: 1810px) {
  html.utwente--ws2021 .widgetsblock.widgetsblock--fullwidth .widgetsblock__contents {
    width: 1490px;
  }
}
@media (min-width: 2190px) {
  html.utwente--ws2021 .widgetsblock.widgetsblock--fullwidth .widgetsblock__contents {
    width: 1870px;
  }
}
@media (min-width: 2570px) {
  html.utwente--ws2021 .widgetsblock.widgetsblock--fullwidth .widgetsblock__contents {
    width: 2250px;
  }
}
html.utwente--ws2021 .widgetsblock .main__contents > .widgetsblock,
html.utwente--ws2021 .widgetsblock .foldabledeeplink__text > .widgetsblock {
  margin-bottom: 0;
}
html.utwente--ws2021 .widgetsblock.widgetsblock .widgetsholder__title {
  background: transparent;
  width: auto;
}
html.utwente--ws2021 .widgetsblock.widgetsblock .widgetsblock--dompackmasonry::before {
  content: "1:30";
  display: block;
  pointer-events: none;
  visibility: hidden;
}
@media (min-width: 640px) {
  html.utwente--ws2021 .widgetsblock.widgetsblock .widgetsblock--dompackmasonry::before {
    content: "2:30";
  }
}
@media (min-width: 751px) {
  html.utwente--ws2021 .widgetsblock.widgetsblock .widgetsblock--dompackmasonry::before {
    content: "3:30";
  }
}
@media (min-width: 1810px) {
  html.utwente--ws2021 .widgetsblock.widgetsblock--fullwidth .widgetsblock--dompackmasonry::before {
    content: "4:30";
  }
}
@media (min-width: 2190px) {
  html.utwente--ws2021 .widgetsblock.widgetsblock--fullwidth .widgetsblock--dompackmasonry::before {
    content: "5:30";
  }
}
@media (min-width: 2570px) {
  html.utwente--ws2021 .widgetsblock.widgetsblock--fullwidth .widgetsblock--dompackmasonry::before {
    content: "6:30";
  }
}
html.utwente--ws2021 .widgetsblock.widgetsblock.widgetsblock--1cols .widgetsholder__titleholder,
html.utwente--ws2021 .widgetsblock.widgetsblock.widgetsblock--1cols .widgetsblock__contents, html.utwente--ws2021 .widgetsblock.widgetsblock.widgetsblock--2cols .widgetsholder__titleholder,
html.utwente--ws2021 .widgetsblock.widgetsblock.widgetsblock--2cols .widgetsblock__contents {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-8col-layout);
}
@media (min-width: 640px) {
  html.utwente--ws2021 .widgetsblock.widgetsblock.widgetsblock--1cols .widgetsblock--dompackmasonry::before, html.utwente--ws2021 .widgetsblock.widgetsblock.widgetsblock--2cols .widgetsblock--dompackmasonry::before {
    display: none;
    content: "2:30";
  }
}
@media (min-width: 640px) {
  html.utwente--ws2021 .widgetsblock.widgetsblock .widgetsblock__contents--singlewidget .widget {
    max-width: calc((100% - var(--main-grid-gap)) / 2);
  }
  html.utwente--ws2021 .widgetsblock.widgetsblock .widgetsblock__contents--singlewidget .widget.main__contents--twocol {
    max-width: 100%;
  }
}
html.utwente--ws2021 .footer .widgetsblock {
  margin-top: 0;
}
html.utwente--ws2021 .applyforprogrammecta__form {
  margin: 0;
  position: relative;
}
html.utwente--ws2021 .applyforprogrammecta__form .wh-form__page {
  padding: 0 25px;
}
html.utwente--ws2021 .applyforprogrammecta__form .wh-form__buttongroup {
  margin-bottom: 20px;
  padding-left: max(25px, 100% - 378px - 25px);
  padding-right: 25px;
}
html.utwente--ws2021 .applyforprogrammecta .inlinecta__button {
  margin-bottom: 20px;
}
html.utwente--ws2021 .coursepage__form::before {
  left: 0;
}
html.utwente--ws2021 .coursepage__form .wh-form__textblock,
html.utwente--ws2021 .coursepage__form .questionsgroup,
html.utwente--ws2021 .coursepage__form .resulttext {
  grid-column: var(--main-8col-layout);
  padding-left: 0;
  padding-right: 0;
}
html.utwente--ws2021 .coursepage__form .loginbar {
  grid-column: var(--main-8col-layout);
}
html.utwente--ws2021 .widewithlines,
html.utwente--ws2021 .widezebra {
  margin-left: unset;
  margin-right: unset;
  width: 100%;
  grid-column: var(--main-12col-layout);
}
html.utwente--ws2021 .embeddedobject:not(.foldablecontent), html.utwente--ws2021 .embeddedobject.foldablecontent:last-child, html.utwente--ws2021 .wh-video:not(.foldablecontent), html.utwente--ws2021 .wh-video.foldablecontent:last-child {
  margin-bottom: 0;
}
html.utwente--ws2021 .embeddedobject > *:first-child, html.utwente--ws2021 .wh-video > *:first-child {
  margin-top: 0;
}
html.utwente--ws2021 h2 + .embeddedobject__programmedetails,
html.utwente--ws2021 .foldabledeeplink__text > .embeddedobject__programmedetails:first-child,
html.utwente--ws2021 h2 + .embeddedobject.tablewithtabs,
html.utwente--ws2021 .foldabledeeplink__text > .embeddedobject.tablewithtabs:first-child,
html.utwente--ws2021 h2 + .embeddedobject--imageswithcaption,
html.utwente--ws2021 .foldabledeeplink__text > .embeddedobject--imageswithcaption:first-child,
html.utwente--ws2021 h2 + .embeddedobject--newseventsrecent,
html.utwente--ws2021 .foldabledeeplink__text > .embeddedobject--newseventsrecent:first-child,
html.utwente--ws2021 h2 + .embeddedobject--linkspercategory_tabs,
html.utwente--ws2021 .foldabledeeplink__text > .embeddedobject--linkspercategory_tabs:first-child,
html.utwente--ws2021 h2 + .embeddedobject--slideshow,
html.utwente--ws2021 .foldabledeeplink__text > .embeddedobject--slideshow:first-child {
  margin-top: 25px !important;
}
html.utwente--ws2021 .inlinecta__button {
  background: var(--theme-color-cta);
}
html.utwente--ws2021 .inlinecta__button:hover {
  background: var(--theme-color-cta-hover);
}
html.utwente--ws2021 .inlinecta__icon {
  color: var(--theme-color-cta);
}
html.utwente--ws2021 .embeddedobject--newseventsrecent.embeddedobject--newseventsrecent.embeddedobject--newseventsrecent {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-full-layout);
}
html.utwente--ws2021 .embeddedobject--newseventsrecent.embeddedobject--newseventsrecent.embeddedobject--newseventsrecent .newseventsrecent__columns {
  display: grid;
  width: 100%;
  column-gap: var(--main-grid-gap);
  grid-column-gap: var(--main-grid-gap);
  grid-template-columns: var(--main-grid);
}
html.utwente--ws2021 .embeddedobject--newseventsrecent.embeddedobject--newseventsrecent.embeddedobject--newseventsrecent .newseventsrecent__tabstrip__item:first-child {
  padding-left: var(--newseventsrecent-sidebar-padding);
}
html.utwente--ws2021 .embeddedobject--newseventsrecent.embeddedobject--newseventsrecent.embeddedobject--newseventsrecent .newseventsrecent__tabstrip__item:last-child {
  padding-right: var(--newseventsrecent-sidebar-padding);
}
html.utwente--ws2021 .embeddedobject--newseventsrecent.embeddedobject--newseventsrecent.embeddedobject--newseventsrecent .newseventsrecent__news {
  grid-column: var(--newseventsrecent-news-columns);
  overflow: hidden;
  width: auto;
}
html.utwente--ws2021 .embeddedobject--newseventsrecent.embeddedobject--newseventsrecent.embeddedobject--newseventsrecent .newseventsrecent__news__meta {
  display: block;
  overflow: visible;
}
html.utwente--ws2021 .embeddedobject--newseventsrecent.embeddedobject--newseventsrecent.embeddedobject--newseventsrecent .newseventsrecent__news__title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin-top: 0;
}
html.utwente--ws2021 .embeddedobject--newseventsrecent.embeddedobject--newseventsrecent.embeddedobject--newseventsrecent .newseventsrecent__news__item--highlight .newseventsrecent__news__title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  overflow: hidden;
}
html.utwente--ws2021 .embeddedobject--newseventsrecent.embeddedobject--newseventsrecent.embeddedobject--newseventsrecent .newseventsrecent__news__description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
html.utwente--ws2021 .embeddedobject--newseventsrecent.embeddedobject--newseventsrecent.embeddedobject--newseventsrecent .newseventsrecent__sidebar {
  grid-column: var(--newseventsrecent-sidebar-columns);
  overflow: hidden;
  width: auto;
}
html.utwente--ws2021 .embeddedobject--newseventsrecent.embeddedobject--newseventsrecent.embeddedobject--newseventsrecent .newseventsrecent__event,
html.utwente--ws2021 .embeddedobject--newseventsrecent.embeddedobject--newseventsrecent.embeddedobject--newseventsrecent .newseventsrecent__sidebar .newseventsrecent__readmore {
  padding-left: var(--newseventsrecent-sidebar-padding);
  padding-right: var(--newseventsrecent-sidebar-padding);
}
html.utwente--ws2021 .embeddedobject--newseventsrecent__title.embeddedobject--newseventsrecent__title.embeddedobject--newseventsrecent__title {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-embed-title-layout);
  padding-left: 0;
  padding-right: 0;
}
html.utwente--ws2021 .embeddedobject--slideshow {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-full-layout);
  flex-direction: var(--slideshow-flex-direction);
  height: var(--slideshow-height);
}
html.utwente--ws2021 .embeddedobject--slideshow .slideshowwidget__panelleft {
  flex: var(--slideshow-panel-flex);
  order: var(--slideshow-panelleft-order);
  padding-left: calc((100% - var(--main-grid-width)) / 2) !important;
}
html.utwente--ws2021 .embeddedobject--slideshow .slideshowwidget__panelleft .slideshowwidget__x {
  padding: var(--slideshow-panelleft-padding) !important;
  width: var(--slideshow-panelleft-width);
}
html.utwente--ws2021 .embeddedobject--slideshow .slideshowwidget__panelright {
  flex: var(--slideshow-panel-flex);
  height: var(--slideshow-panelright-height);
  order: var(--slideshow-panelright-order);
}
html.utwente--ws2021 .embeddedobject--slideshow .slideshowwidget__slideinfo__description > * {
  max-width: none;
}
html.utwente--ws2021 .carrouselwidget-title {
  padding: 0 20px;
}
html.utwente--ws2021 .fsslideshow {
  position: fixed;
}
html.utwente--ws2021 .embeddedobject--linkspercategory_color.embeddedobject--linkspercategory_color.embeddedobject--linkspercategory_color,
html.utwente--ws2021 .embeddedobject--linkspercategory_tabs.embeddedobject--linkspercategory_tabs.embeddedobject--linkspercategory_tabs,
html.utwente--ws2021 .lpcc__container.lpcc__container {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-12col-layout);
  z-index: var(--zindex-above-deeplinks);
}
html.utwente--ws2021 .embeddedobject--linkspercategory_color.embeddedobject--linkspercategory_color.embeddedobject--linkspercategory_color.embeddedobject.hasfoldabledeeplink,
html.utwente--ws2021 .embeddedobject--linkspercategory_tabs.embeddedobject--linkspercategory_tabs.embeddedobject--linkspercategory_tabs.embeddedobject.hasfoldabledeeplink,
html.utwente--ws2021 .lpcc__container.lpcc__container.embeddedobject.hasfoldabledeeplink {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-full-layout);
}
html.utwente--ws2021 .lpcc__title,
html.utwente--ws2021 .lpct__title {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-embed-title-layout);
}
html.utwente--ws2021 .embeddedobject--linkswithimage.embeddedobject--linkswithimage.embeddedobject--linkswithimage {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-12col-layout);
}
html.utwente--ws2021 .embeddedobject--banner {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-full-layout);
  z-index: var(--zindex-main);
}
html.utwente--ws2021 .embeddedobject--banner .banner__slide__content {
  margin: auto;
  padding: 0;
  width: var(--banner-slide-width);
}
html.utwente--ws2021 .embeddedobject--banner .banner__slide__meta {
  padding: var(--banner-slide-padding);
}
html.utwente--ws2021 .embeddedobject--banner .banner__overlay {
  margin: auto;
  padding: 0;
  width: var(--banner-overlay-width);
}
html.utwente--ws2021 .embeddedobject--banner .banner__slidedots {
  bottom: 25px;
  left: 0;
}
html.utwente--ws2021 .linksgridwidget {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-full-layout);
  padding-bottom: var(--main-grid-gap);
  padding-top: var(--main-grid-gap);
}
html.utwente--ws2021 .linksgridwidget.linksgridwidget .linksgridwidget__title {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-12col-layout);
}
@media (max-width: 750px) {
  html.utwente--ws2021 .linksgridwidget.linksgridwidget .linksgridwidget__title {
    margin-left: unset;
    margin-right: unset;
    width: unset;
    grid-column: var(--main-8col-layout);
  }
}
html.utwente--ws2021 .linksgridwidget.linksgridwidget .linksgridwidget__item {
  width: calc((100% - 60px) / 3) !important;
}
@media (max-width: 999px) {
  html.utwente--ws2021 .linksgridwidget.linksgridwidget .linksgridwidget__item {
    width: calc((100% - 30px) / 2) !important;
  }
}
@media (max-width: 639px) {
  html.utwente--ws2021 .linksgridwidget.linksgridwidget .linksgridwidget__item {
    width: 100% !important;
  }
}
html.utwente--ws2021 .linksgridwidget .widgetsblock {
  margin-top: 0;
  padding-left: 0;
  padding-right: 0;
}
html.utwente--ws2021 .widget__issuu {
  background-color: transparent;
}
html.utwente--ws2021 .widget__issuu .widget__title {
  color: var(--color-black);
}
html.utwente--ws2021 .widget__issuu div[data-needsconsent] {
  position: relative;
}
html.utwente--ws2021 .widget__issuu--embedded {
  display: grid;
  width: 100%;
  column-gap: var(--main-grid-gap);
  grid-column-gap: var(--main-grid-gap);
  grid-template-columns: var(--main-grid);
}
html.utwente--ws2021 .embeddedobject--timeline {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-full-layout);
  z-index: var(--zindex-above-deeplinks);
}
html.utwente--ws2021 .embeddedobject--timeline .timeline__bookmarks {
  top: calc(50px + var(--navbar-height));
}
html.utwente--ws2021 .embeddedobject--timeline .timeline__content {
  display: grid;
  width: 100%;
  column-gap: var(--main-grid-gap);
  grid-column-gap: var(--main-grid-gap);
  grid-template-columns: var(--main-grid);
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-full-layout);
}
html.utwente--ws2021 .embeddedobject--timeline .timeline__intro {
  margin-left: -36px;
  margin-right: -36px;
  width: unset;
  grid-column: var(--main-8col-layout);
}
html.utwente--ws2021 .embeddedobject--timeline .timeline__noresults {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-8col-layout);
  margin-bottom: -100%;
  position: static;
}
html.utwente--ws2021 .embeddedobject--timeline .timeline__introline {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-12col-layout);
  background: none;
  position: relative;
}
html.utwente--ws2021 .embeddedobject--timeline .timeline__introline::after {
  background: var(--color-grey-lines);
  bottom: 0;
  content: "";
  display: block;
  left: 50%;
  margin-left: -0.5px;
  position: absolute;
  top: 0;
  width: 1px;
}
html.utwente--ws2021 .embeddedobject--timeline .timeline__items {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-12col-layout);
}
html.utwente--ws2021 .widget__relatedprogrammes {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-full-layout);
}
html.utwente--ws2021 .widget-quote--embeddedobject {
  margin-bottom: 0;
}
html.utwente--ws2021 .timetablewidget {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-full-layout);
  z-index: var(--zindex-above-deeplinks);
}
html.utwente--ws2021 .timetablewidget .ttw-headerbar {
  padding-left: calc((100% - var(--main-content-width)) / 2);
  padding-right: var(--main-grid-gap);
}
html.utwente--ws2021 .timetablewidget .ttw-buttonsbar {
  margin-top: 12px;
}
html.utwente--ws2021 .timetablewidget.ttw-fullscreen {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: var(--zindex-modaldialog);
}
html.utwente--ws2021 .widget--foldable {
  margin-top: 30px;
}
html.utwente--ws2021 .widget--foldable + .widget--foldable {
  margin-top: -1px;
}
html.utwente--ws2021 .tablewithfilter .overflow::after {
  right: 0;
}
html.utwente--ws2021 .widget-mastercareerprospects {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-full-layout);
  display: grid;
  width: 100%;
  column-gap: var(--main-grid-gap);
  grid-column-gap: var(--main-grid-gap);
  grid-template-columns: var(--main-grid);
}
html.utwente--ws2021 .widget-mastercareerprospects__header {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-8col-layout);
}
html.utwente--ws2021 .widget-mastercareerprospects__inner {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-full-layout);
  display: grid;
  width: 100%;
  column-gap: var(--main-grid-gap);
  grid-column-gap: var(--main-grid-gap);
  grid-template-columns: var(--main-grid);
}
html.utwente--ws2021 .widget-mastercareerprospects__stats {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-8col-layout);
}
html.utwente--ws2021 .widget-mastercareerprospects__brochure {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-8col-layout);
}
@media (max-width: 749px) {
  html.utwente--ws2021 .widget-mastercareerprospects__inner {
    background: transparent;
  }
  html.utwente--ws2021 .widget-mastercareerprospects__stats__nl::before,
  html.utwente--ws2021 .widget-mastercareerprospects__stats__ov::before {
    background-size: cover;
    bottom: unset;
    height: 50vw;
  }
}
@media (max-width: 600px) {
  html.utwente--ws2021 .widget-mastercareerprospects__stats__count-date {
    margin-left: calc((var(--main-content-width) - 100vw) / 2);
    margin-right: calc((var(--main-content-width) - 100vw) / 2);
    padding-left: calc((100vw - var(--main-content-width)) / 2);
    padding-right: calc((100vw - var(--main-content-width)) / 2);
  }
}
html.utwente--ws2021 .embeddedobject__studyfinder {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-12col-layout);
}
html.utwente--ws2021 .embeddedobject__studyfinder .studyfinder__programme {
  display: block;
}
html.utwente--ws2021 .embeddedobject__studyfinder .filteredoverview__widgetheader__content,
html.utwente--ws2021 .embeddedobject__studyfinder .programme__contentwrapper,
html.utwente--ws2021 .embeddedobject__studyfinder .programme_specializations__content {
  margin-left: 0;
  margin-right: 0;
  width: auto;
}
html.utwente--ws2021 .embeddedobject__transfer .foldableprog__title {
  width: auto;
}
html.utwente--ws2021 .embeddedobject__transfer .foldableprog__title::after {
  transition: color 300ms;
}
html.utwente--ws2021 .embeddedobject__transfer .foldableprog__title:hover::after {
  color: var(--color-grey-dark);
}
html.utwente--ws2021 .embeddedobject__transfer .foldableprog--open .foldableprog__title::after {
  color: var(--color-grey-dark);
}
html.utwente--ws2021 .widget-brochurerequestform_inline .wh-form__fieldgroup[data-wh-form-group-for="permission.checkbox"] {
  margin-left: unset;
}
html.utwente--ws2021 .summaryarchivetabs,
html.utwente--ws2021 .summary--newsevents .summary__items {
  margin-top: 50px;
}
html.utwente--ws2021 .main__contents.osiris__content {
  display: grid;
  width: 100%;
  column-gap: var(--main-grid-gap);
  grid-column-gap: var(--main-grid-gap);
  grid-template-columns: var(--main-grid);
  padding-bottom: var(--main-padding-bottom);
}
html.utwente--ws2021 .main__contents.osiris__content > * {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-8col-layout);
}
html.utwente--ws2021 .main__contents.osiris__content .maxpoints {
  margin-top: 0;
}
html.utwente--ws2021 .peopleoverview__viewtype {
  line-height: 16px;
}
html.utwente--ws2021 .peopleoverview__viewtype > span:hover {
  text-decoration: none;
}
html.utwente--ws2021 .peopleoverview__viewtype-icon {
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 1px;
}
html.utwente--ws2021 .peopleoverview__viewtype-label {
  display: none;
}
html.utwente--ws2021 .peopleoverview__showaslist,
html.utwente--ws2021 .peopleoverview__showasgrid,
html.utwente--ws2021 .peopleoverview__showassummary {
  margin-left: 40px;
  opacity: 0.5;
  transition: opacity 0.2s;
}
html.utwente--ws2021 .peopleoverview__showaslist:hover,
html.utwente--ws2021 .peopleoverview__showasgrid:hover,
html.utwente--ws2021 .peopleoverview__showassummary:hover {
  opacity: 1;
}
html.utwente--ws2021 .peopleoverview__viewtype-label + .peopleoverview__showaslist,
html.utwente--ws2021 .peopleoverview__viewtype-label + .peopleoverview__showasgrid,
html.utwente--ws2021 .peopleoverview__viewtype-label + .peopleoverview__showassummary {
  margin-left: 0;
}
html.utwente--ws2021.peopleoverview--showaslist .peopleoverview__showaslist, html.utwente--ws2021.peopleoverview--showasgrid .peopleoverview__showasgrid, html.utwente--ws2021.peopleoverview--showassummary .peopleoverview__showassummary {
  opacity: 1;
  text-decoration: none;
}
html.utwente--ws2021 .contentpart__main > .main__contents--12col.peopleoverview__listview {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--people-overiew-list-columns);
}
html.utwente--ws2021 .contentpart__main > .main__contents--12col.peopleoverview__gridview {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-full-layout);
  background: var(--color-grey-lighter);
  padding-bottom: 50px;
}
html.utwente--ws2021 ut-medical-form {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-full-layout);
}
html.utwente--ws2021 .coursefinder__page {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-full-layout);
}
html.utwente--ws2021 .footer {
  display: flex;
  flex-direction: column;
}
html.utwente--ws2021 .footer .widgetsblock {
  background: var(--color-grey-light);
}
html.utwente--ws2021 .footer__prefooter {
  order: 1;
}
html.utwente--ws2021 .footer__links {
  order: 3;
}
html.utwente--ws2021 .footer__footer {
  order: 4;
}
html.utwente--ws2021 .footer__prefooter,
html.utwente--ws2021 .footer__links,
html.utwente--ws2021 .footer__footer {
  padding-left: var(--footer-padding-left);
  padding-right: var(--footer-padding-right);
}
html.utwente--ws2021 .footer__prefooter:last-child,
html.utwente--ws2021 .footer__links:last-child,
html.utwente--ws2021 .footer__footer:last-child {
  padding-bottom: var(--footer-padding-bottom);
}
html.utwente--ws2021 .footer__linksblock,
html.utwente--ws2021 .footer__footer {
  display: grid;
  width: 100%;
  column-gap: var(--main-grid-gap);
  grid-column-gap: var(--main-grid-gap);
  grid-template-columns: var(--main-grid);
}
html.utwente--ws2021 .footer__prefooter {
  height: var(--footer-prefooter-height);
  padding-bottom: 0;
}
html.utwente--ws2021 .footer__prefooter-container {
  display: var(--footer-prefooter-display);
  width: 100%;
  column-gap: var(--main-grid-gap);
  grid-column-gap: var(--main-grid-gap);
  grid-template-columns: var(--main-grid);
  background: var(--color-white);
}
html.utwente--ws2021 .footer--crumb-share-below:not([data-contentabove=embeddedobject--banner]):not([data-contentabove=embeddedobject--slideshow]) .footer__addressblock {
  border: none;
}
html.utwente--ws2021 .footer--crumb-share-below:not([data-contentabove=embeddedobject--banner]):not([data-contentabove=embeddedobject--slideshow]) .footer__prefooter-container::before {
  background-color: var(--color-grey-lines);
  content: "";
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
html.utwente--ws2021 .footer__addressblock {
  background: transparent;
  grid-column: var(--footer-address-columns);
  padding-left: var(--footer-address-padding);
  padding-right: var(--footer-address-padding);
  width: var(--footer-address-width);
}
html.utwente--ws2021 .footer__image {
  grid-column: var(--footer-image-columns);
}
html.utwente--ws2021 .footer__sharepage {
  grid-column: var(--footer-share-column);
  padding-left: 0;
  padding-right: 0;
  width: auto;
}
html.utwente--ws2021 .footer__footerlinks {
  grid-column: var(--footer-footerlinks-column);
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}
html.utwente--ws2021 .footer__linksblock {
  margin: 0;
  max-width: unset;
}
html.utwente--ws2021 .footer__linksection {
  float: none;
  padding: 0;
  width: auto;
  grid-column: var(--footer-links-column-1);
}
html.utwente--ws2021 .footer__linksection:nth-child(2) {
  grid-column: var(--footer-links-column-2);
}
html.utwente--ws2021 .footer__linksection:nth-child(3) {
  grid-column: var(--footer-links-column-3);
}
html.utwente--ws2021 .footer__linksection:nth-child(4) {
  grid-column: var(--footer-links-column-4);
}
html.utwente--ws2021 .footer__logos {
  left: auto;
  margin-left: auto;
  margin-right: auto;
}
html.utwente--ws2021 .cobrowserchatcontrol {
  z-index: var(--zindex-cobrowser);
}
html.utwente--ws2021 .searchsheet {
  left: 0;
  top: var(--searsia-sheet-top);
  z-index: var(--zindex-searchsheet);
}
html.utwente--ws2021 .searchsheet .searchsheet__header {
  border: none;
}
html.utwente--ws2021 .searchsheet .searchsheet__searchform {
  margin-left: var(--searsia-form-margin);
  width: var(--searsia-form-width);
}
html.utwente--ws2021 .searchsheet .searchsheet__searchfield {
  padding: 0;
}
html.utwente--ws2021 .searchsheet .searchsheet__searchfield-clear {
  display: none;
}
html.utwente--ws2021 .searchsheet .searchsheet__close {
  align-self: start;
  background: var(--searsia-close-background-color);
  border-radius: 0;
  color: var(--searsia-close-color);
  display: var(--searsia-close-display);
  font-size: 14px;
  height: var(--navbar-height);
  line-height: var(--navbar-height);
  margin-top: 0;
  overflow: hidden;
  padding: 0;
  right: 0;
  top: 0;
  width: var(--navbar-height);
}
html.utwente--ws2021 .searchsheet .searchsheet__container {
  height: calc(100vh - 80px - var(--searsia-sheet-top));
}

html[dir=ltr].utwente--ws2021 .footer__linksblock {
  margin: 0;
  width: 100%;
}

.ut-dialog__modalbg,
.dialog__modal {
  z-index: var(--zindex-modaldialog);
}