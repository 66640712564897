@import "@mod-utwente_design/scss/config-colors.css";
:root {
  --zindex-modaldialog: 12;
  --zindex-popup: 11;
  --zindex-cobrowser: 10;
  --zindex-searchsheet: 9;
  --zindex-hamburger: 8;
  --zindex-menu: 7;
  --zindex-menumodal: 6;
  --zindex-messagesarea: 5;
  --zindex-navbar: 4;
  --zindex-above-deeplinks: 3;
  --zindex-deeplinks: 2;
  --zindex-main: 1;
  --zindex-header-cta: 6;
  --zindex-title: 5;
  --zindex-itc-swoosh: 4;
  --zindex-header: 2;
  --zindex-headerbg: -1;
  --breakpoint-flexgrid: 1430px;
  --breakpoint-menuhidden: 1279px;
  --breakpoint-deeplinks: 959px;
  --breakpoint-tablet: 749px;
  --breakpoint-phone: 480px;
  --menupart-translation: none;
  --menu-width: 260px;
  --menu-height: auto;
  --menu-transition-duration: 250ms;
  --menu-topmenu-display: none;
  --menu-topmenu-items-display: none;
  --menu-topmenu-title-border: none;
  --menu-padding: 40px;
  --menu-header-spacing: 0;
  --menu-indentation: 20px;
  --menu-item-height: 40px;
  --menu-item-transition-duration: 150ms;
  --contentpart-margin: var(--menu-width);
  --hamburger-display: none;
  --hamburger-top: 0px;
  --header-navbar-height: 0;
  --header-margin: 0;
  --header-padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
  --header-background-height: 260px;
  --header-grid-rows: [top] auto [header-top] 1fr [title-above] calc(var(--header-background-height) - 2 * var(--header-sliderdots-height)) [title-below] var(--header-sliderdots-height) [bottom];
  --header-spacing: 30px;
  --header-photos-width: 1600px;
  --header-photos-height: 640px;
  --header-photos-max-height: 640px;
  --header-photos-aspect-padding: 0;
  --header-videos-display: block;
  --header-videomobile-display: none;
  --header-videos-width: 1280px;
  --header-videos-height: 352px;
  --header-videos-aspect-padding: 0;
  --header-photos-fixed-height: 320px;
  --header-sliderdots-height: 45px;
  --header-sliderdots-display: flex;
  --header-slideshow-duration: 1s;
  --header-author-height: 16px;
  --header-author-bottom: calc((var(--header-sliderdots-height) - var(--header-author-height)) / 2);
  --header-author-right: calc((var(--header-sliderdots-height) - var(--header-author-height)) / 2);
  --header-filters-height: 100px;
  --header-filters-margin: 0;
  --header-title-margin: 0;
  --universe-rotation-duration: 180s;
  --notification-grid-area: grid-left / grid-right;
  --notification-cookiebar-separator: none;
  --notification-padding: 20px 64px 16px;
  --header-cta-width: 320px;
  --header-cta-padding-vert: 20px;
  --header-cta-padding-hor: 20px;
  --header-cta-flex-direction: column;
  --header-title-cta-padding: 0;
  --header-overlay-width: 125px;
  --header-overlay-position: absolute;
  --header-overlay-left: calc(((100% - var(--main-grid-width)) / 2) + 20px);
  --header-overlay-transform: translateY(calc(100% - 100px));
  --main-grid-padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
  --main-grid-column: 65px;
  --main-grid-gap: 30px;
  --main-content-width: calc(8 * var(--main-grid-column) + 7 * var(--main-grid-gap));
  --main-grid-width: calc(12 * var(--main-grid-column) + 11 * var(--main-grid-gap));
  --main-grid-margin: 1fr;
  /*
  [left] [grid-left] [text-left]                                 [text-right] [grid-right] [right]
    |        |           |                                               |           |        |
    | margin | 2 columns |                   8 columns                   | 2 columns | margin |
             |     |     |     |     |     |     |     |     |     |     |     |     |
             |                              12 columns                               |

    By default, all content columns have a fixed width and the margins have a width of 1fr. If the width is too small to
    contain the fixed-width columns, the columns get a width of 1fr and the margins are set to 0. There will always be at
    least a gutter-sized padding left and right.
  */
  --main-grid: [left] minmax(env(safe-area-inset-left), var(--main-grid-margin)) [grid-left] repeat(2, var(--main-grid-column)) [text-left] repeat(8, var(--main-grid-column)) [text-right] repeat(2, var(--main-grid-column)) [grid-right] minmax(env(safe-area-inset-right), var(--main-grid-margin)) [right];
  --main-8col-layout: text-left / text-right;
  --main-12col-layout: grid-left / grid-right;
  --main-full-layout: left / right;
  --main-embed-title-layout: grid-left / grid-right;
  --main-padding-bottom: 50px;
  --widget-equalheight-width: calc((var(--main-grid-width) - 2 * var(--main-grid-gap) - 1px) / 3);
  --newseventsrecent-news-columns: grid-left / span 8;
  --newseventsrecent-sidebar-columns: span 4 / grid-right;
  --newseventsrecent-sidebar-padding: 25px;
  --slideshow-height: 450px;
  --slideshow-flex-direction: row;
  --slideshow-panel-flex: 0 0 50%;
  --slideshow-panelleft-order: 1;
  --slideshow-panelleft-padding: 0 var(--main-grid-gap) 0 0;
  --slideshow-panelleft-width: auto;
  --slideshow-panelright-order: 2;
  --slideshow-panelright-height: auto;
  --banner-slide-width: var(--main-grid-width);
  --banner-slide-padding: 0 100px 0 0;
  --banner-overlay-width: var(--main-grid-width);
  --people-overiew-list-columns: grid-left / span 8;
  --footer-padding-right: env(safe-area-inset-right);
  --footer-padding-bottom: env(safe-area-inset-bottom);
  --footer-padding-left: env(safe-area-inset-left);
  --footer-prefooter-display: grid;
  --footer-prefooter-height: 300px;
  --footer-address-columns: grid-left / span 4;
  --footer-address-padding: 0px;
  --footer-address-width: auto;
  --footer-image-columns: span 9 / right;
  --footer-share-column: span 3 / grid-right;
  --footer-links-column-1: 2 / span 3;
  --footer-links-column-2: 5 / span 3;
  --footer-links-column-3: 8 / span 3;
  --footer-links-column-4: 11 / span 3;
  --footer-footerlinks-column: var(--main-12col-layout);
  --deeplinks-display: block;
  --deeplinks-width: 160px;
  --deeplinks-margin: 50px;
  --deeplinks-padding: 10px;
  --deeplinks-top: calc(var(--navbar-height) + var(--deeplinks-margin));
  --cta-font-size: 40px;
  --cta-line-height: 40px;
  --searsia-sheet-top: var(--navbar-height);
  --searsia-form-width: 920px;
  --searsia-form-margin: calc(50% - var(--searsia-form-width) / 2);
  --searsia-close-display: none;
  --searsia-close-color: var(--color-white);
  --searsia-close-background-color: var(--color-black);
}

html.ut--nonbranding {
  --searsia-sheet-top: 0px;
  --searsia-close-display: block;
  --searsia-close-color: var(--color-black);
  --searsia-close-background-color: var(--color-white);
}

:root {
  --navbar-position: sticky;
  --navbar-flex-direction: column;
  --navbar-top: 0;
  --navbar-height: 60px;
  --navbar-padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
  --navbar-background-color: var(--color-black);
  --navbar-color: var(--color-white);
  --navbar-menu-display: block;
  --navbar-menu-width: 240px;
  --navbar-menu-padding: 10px;
  --navbar-menu-item-height: 40px;
  --navbar-menu-item-padding: 20px;
  --navbar-menu-border-color: var(--color-grey-dark);
  --navbar-menu-border-padding: 0;
}

html.ut--nonbranding {
  --navbar-background-color: var(--color-grey-light);
  --navbar-color: var(--color-black);
  --navbar-menu-border-color: var(--color-grey-lines);
  --navbar-menu-border-padding: 10px;
}

.contentpart__navbar-crumbs {
  --breadcrumbs-padding: 0 30px;
  --breadcrumbs-color: var(--color-white);
  --breadcrumbs-display: block;
}
.contentpart__navbar-crumbs .contentpart__breadcrumblist {
  --breadcrumbs-display: flex;
}
html.ut--nonbranding .contentpart__navbar-crumbs {
  --breadcrumbs-color: var(--color-black);
}

.contentpart__header-crumbs {
  --breadcrumbs-padding: 10px calc(20px + env(safe-area-inset-right)) 10px calc(20px + env(safe-area-inset-left));
  --breadcrumbs-color: var(--color-black);
  --breadcrumbs-display: none;
}
.contentpart__header-crumbs .contentpart__breadcrumblist {
  --breadcrumbs-display: flex;
}

.menupart {
  --logo-display: flex;
  --identity-display: block;
  --identity-margin: 0;
  --identity-titles-margin: 0 20px 2px 0;
  --identity-titles-padding: 30px env(safe-area-inset-right) 10px calc(20px + env(safe-area-inset-left));
  --identity-titles-lineheight: 22px;
  --identity-branding-logo-margin-hor: calc(20px + max(env(safe-area-inset-left), env(safe-area-inset-right)));
  --identity-branding-logo-margin-vert: 20px;
  --identity-branding-partner-navbar-height: 40px;
  --identity-itc-logo-width: 52px;
  --identity-itc-logo-top: 0px;
  /* Tweak some settings for ITC */
  /* Tweak some settings for non-branding logo sites */
}
.menupart .identitypart__site--itc {
  --identity-titles-padding: 15px calc(25px + env(safe-area-inset-right)) 0 calc(25px + env(safe-area-inset-left));
}
.menupart .identitypart__site--branding {
  --identity-titles-margin: 0;
  --identity-titles-padding: var(--identity-branding-logo-margin-vert) var(--identity-branding-logo-margin-hor) 0;
}

.contentpart {
  --logo-display: none;
  --identity-display: none;
  --identity-margin: calc(var(--navbar-height) + env(safe-area-inset-left));
  --identity-titles-margin: 0 20px 2px 0;
  --identity-titles-padding: 0 env(safe-area-inset-right) calc(((var(--navbar-height) - var(--identity-titles-lineheight)) / 2) - 2px) 20px;
  --identity-titles-lineheight: 22px;
  --identity-branding-logo-margin-hor: 20px;
  --identity-branding-logo-margin-vert: 20px;
  --identity-branding-partner-navbar-height: var(--navbar-height);
  --identity-itc-logo-width: 52px;
  --identity-itc-logo-top: -11px;
  /* Tweak some settings for ITC */
  /* Tweak some settings for non-branding logo sites */
}
.contentpart .identitypart__site--itc {
  --identity-titles-padding: 0 20px;
}
.contentpart .identitypart__site--branding {
  --identity-titles-margin: 0;
  --identity-titles-padding: 0 var(--identity-branding-logo-margin-hor) var(--identity-branding-logo-margin-vert);
}

html.site--itc {
  --header-sliderdots-height: 90px;
  --header-author-bottom: 0;
  --header-author-right: 0;
  --header-filters-margin: 60px; /* push content at the bottom of the page header upwards so it's not fully on the ITC swoosh */
}

.cta__button__title--default {
  --header-cta-button-display: inline;
}

.cta__button__title--mobile {
  --header-cta-button-display: none;
}

html.ws2016--iframed {
  --menu-width: 0px;
}

@media (max-width: 1820px) {
  :root {
    /* Scale header photo/video proportionally */
    --header-photos-width: auto;
    --header-photos-height: auto;
    --header-photos-aspect-padding: calc(320 / 750 * 100%);
    --header-videos-aspect-padding: calc(320 / 750 * 100%);
  }
}
@media (max-width: 1690px) {
  :root {
    /* Update header title CTA padding */
    --header-title-cta-padding: 130px;
  }
}
@media (max-width: 1490px) {
  :root {
    /* Prevent title from sticking to left side of screen */
    --header-title-margin: calc((var(--main-grid-width) + 2 * var(--main-grid-gap) - (100vw - var(--menu-width) - 2 * var(--header-spacing))) / 2);
  }
}
@media (max-width: 1430px) {
  :root {
    /* Make the content columns flexible, hide the margins left and right */
    --main-grid-column: 1fr;
    --main-grid-margin: 0px;
    --main-content-width: calc((100vw - var(--menu-width) - 13 * var(--main-grid-gap)) * 8 / 12 + 7 * var(--main-grid-gap));
    --main-grid-width: calc(100vw - var(--menu-width) - 2 * var(--main-grid-gap));
    /* Prevent title from sticking to left side of screen */
    --header-title-margin: var(--header-spacing);
    /* Update header title CTA padding */
    --header-title-cta-padding: 190px;
  }
}
/*
Options for having the option to hide the menu by default:
1. we dont use a media query (@media (max-width: 1279px)) but set a class
   (using the same code which uses the --breakpoint-menuhidden to open/close the menu based on the viewport size)
   - If the JS code fails, the layout will be messed up
2. use a mixins and publish the overrides in both the media query AND
3. have JS find this media query in the sheet and update it from 1279px to a very high number if .ut--menuhiddenbydefault is set
*/
@media (max-width: 1279px) {
  :root {
    /* Make room for the navbar */
    --header-margin: var(--header-navbar-height);
    /* Remove spacing around header photo */
    --header-spacing: 0px;
    /* Reposition the menu */
    --menupart-translation: translateX(calc(0px - var(--menu-width)));
    --menu-height: 100vh;
    --menu-header-spacing: calc(var(--navbar-height) + 1px);
    --contentpart-margin: 0;
    /* Make notification area take up all width, add separator between cookiebar and notification */
    --notification-grid-area: left / right;
    --notification-cookiebar-separator: 1px solid var(--color-grey-lines);
    /* Scroll navbar with site */
    --navbar-position: absolute;
    /* Show the hamburger and menu top menu title */
    --hamburger-display: block;
    --menu-topmenu-display: flex;
    /* Update header title CTA padding */
    --header-title-cta-padding: 165px;
    /* Remove extra space between photo edge and overlay */
    --header-overlay-left: calc(((100% - var(--main-grid-width)) / 2));
    /* Make the content columns static again */
    --main-grid-column: 65px;
    --main-grid-margin: 1fr;
    --main-content-width: calc(8 * var(--main-grid-column) + 7 * var(--main-grid-gap));
    --main-grid-width: calc(12 * var(--main-grid-column) + 11 * var(--main-grid-gap));
    /* Update deeplinks top position */
    --deeplinks-top: var(--deeplinks-margin);
  }
  /* Make room for site title bar in navbar */
  html.utwente--sitetitlebar {
    --hamburger-top: var(--navbar-height);
  }
  /* Author shouldn't stick to the right side of the screen */
  html.site--itc {
    --header-author-right: 30px;
  }
  /* Navbar and site title are combined */
  html.ut--nonbranding {
    --navbar-flex-direction: row-reverse;
    --navbar-background-color: var(--color-white);
    --hamburger-top: 0;
  }
  /* Hide the menu logo and site titles, show the navbar logo and site titles */
  /* Hide the menu site titles, show the navbar site titles */
  .menupart {
    --logo-display: none;
    --identity-display: none;
  }
  .contentpart {
    --logo-display: flex;
    --identity-display: flex;
  }
}
html.ut--menuhiddenbydefault {
  --menupart-translation: translateX(calc(0px - var(--menu-width))) !important;
  --hamburger-display: block;
  /* Make room for site title bar in navbar */
  /* Navbar and site title are combined */
}
html.ut--menuhiddenbydefault.utwente--sitetitlebar {
  --hamburger-top: var(--navbar-height);
}
html.ut--menuhiddenbydefault.ut--nonbranding {
  --hamburger-top: 0;
}

html.ut--menuhiddenbydefault.utwente--menuhidden {
  /* Make room for site title bar in navbar */
  /* Author shouldn't stick to the right side of the screen */
  /* Navbar and site title are combined */
  /* Hide the menu logo and site titles, show the navbar logo and site titles */
  /* Hide the menu site titles, show the navbar site titles */
  --breakpoint-menuhidden: 13371337px; /* Set Javascript trigger for showing menu very high */
  --contentpart-margin: 0;
}
html.ut--menuhiddenbydefault.utwente--menuhidden :root {
  /* Make room for the navbar */
  --header-margin: var(--header-navbar-height);
  /* Remove spacing around header photo */
  --header-spacing: 0px;
  /* Reposition the menu */
  --menupart-translation: translateX(calc(0px - var(--menu-width)));
  --menu-height: 100vh;
  --menu-header-spacing: calc(var(--navbar-height) + 1px);
  --contentpart-margin: 0;
  /* Make notification area take up all width, add separator between cookiebar and notification */
  --notification-grid-area: left / right;
  --notification-cookiebar-separator: 1px solid var(--color-grey-lines);
  /* Scroll navbar with site */
  --navbar-position: absolute;
  /* Show the hamburger and menu top menu title */
  --hamburger-display: block;
  --menu-topmenu-display: flex;
  /* Update header title CTA padding */
  --header-title-cta-padding: 165px;
  /* Remove extra space between photo edge and overlay */
  --header-overlay-left: calc(((100% - var(--main-grid-width)) / 2));
  /* Make the content columns static again */
  --main-grid-column: 65px;
  --main-grid-margin: 1fr;
  --main-content-width: calc(8 * var(--main-grid-column) + 7 * var(--main-grid-gap));
  --main-grid-width: calc(12 * var(--main-grid-column) + 11 * var(--main-grid-gap));
  /* Update deeplinks top position */
  --deeplinks-top: var(--deeplinks-margin);
}
html.ut--menuhiddenbydefault.utwente--menuhidden html.utwente--sitetitlebar {
  --hamburger-top: var(--navbar-height);
}
html.ut--menuhiddenbydefault.utwente--menuhidden html.site--itc {
  --header-author-right: 30px;
}
html.ut--menuhiddenbydefault.utwente--menuhidden html.ut--nonbranding {
  --navbar-flex-direction: row-reverse;
  --navbar-background-color: var(--color-white);
  --hamburger-top: 0;
}
html.ut--menuhiddenbydefault.utwente--menuhidden .menupart {
  --logo-display: none;
  --identity-display: none;
}
html.ut--menuhiddenbydefault.utwente--menuhidden .contentpart {
  --logo-display: flex;
  --identity-display: flex;
}

@media (max-width: 1170px) {
  :root {
    /* Make the content columns flexible, hide the margins left and right */
    --main-grid-column: 1fr;
    --main-grid-margin: 0px;
    --main-content-width: calc((100vw - 13 * var(--main-grid-gap)) * 8 / 12 + 7 * var(--main-grid-gap));
    --main-grid-width: calc(100vw - 2 * var(--main-grid-gap));
  }
}
@media (max-width: 959px) {
  :root {
    /* Update header title CTA padding */
    --header-title-cta-padding: 200px;
    /* Hide homepage video */
    --header-videos-display: none;
    --header-videomobile-display: block;
    /* Position the overlay statically below the CTA */
    --header-overlay-position: static;
    --header-overlay-width: 90px;
    --header-overlay-transform: none;
    /* Display columns stacked instead of next to each other */
    --newseventsrecent-news-columns: grid-left / grid-right;
    --newseventsrecent-sidebar-columns: grid-left / grid-right;
    /* Update footer prefooter layout*/
    --footer-prefooter-display: flex;
    --footer-prefooter-height: 250px;
    --footer-address-padding: var(--main-grid-gap);
    --footer-address-width: 33.3333333333333333%;
    /* Footer links are made foldable */
    --footer-links-column-1: grid-left / grid-right;
    --footer-links-column-2: grid-left / grid-right;
    --footer-links-column-3: grid-left / grid-right;
    --footer-links-column-4: grid-left / grid-right;
    /* Hide the deeplinks */
    --deeplinks-display: none;
    /* Reduce full-width embedded component title width */
    --main-embed-title-layout: text-left / text-right;
    /* Resize widgets to fit 2 on a row instead of 3 */
    --widget-equalheight-width: calc((var(--main-grid-width) - var(--main-grid-gap) - 1px) / 2);
    /* Adjust search form width */
    --searsia-form-margin: 90px;
    --searsia-form-width: calc(100vw - 2 * var(--searsia-form-margin));
  }
  /* Hide the navbar breadcrumbs, show the header breadcrumbs */
  .contentpart__navbar-crumbs .contentpart__breadcrumblist {
    --breadcrumbs-display: none;
  }
  .contentpart__header-crumbs {
    --breadcrumbs-display: block;
  }
  .contentpart__header-crumbs + .contentpart__header {
    --header-margin: 0;
  }
  html.site--itc {
    --header-sliderdots-height: 60px;
  }
}
@media (max-width: 749px) {
  :root {
    /* Make the main content 10 columns wide */
    --main-grid: [left] minmax(env(safe-area-inset-left), var(--main-grid-margin)) [grid-left] var(--main-grid-column) [text-left] repeat(10, var(--main-grid-column)) [text-right] var(--main-grid-column) [grid-right] minmax(env(safe-area-inset-right), var(--main-grid-margin)) [right];
    --main-content-width: calc((100vw - 13 * var(--main-grid-gap)) * 10 / 12 + 9 * var(--main-grid-gap));
    /* Hide the navbar menu, show the menu top menu items*/
    --navbar-menu-display: none;
    --menu-topmenu-items-display: block;
    --menu-topmenu-title-border: 1px solid var(--color-grey-lines);
    /* Crop header photo/video horizontally instead of scaling proportionally */
    --header-photos-height: var(--header-photos-fixed-height);
    --header-photos-aspect-padding: 0;
    --header-videos-aspect-padding: 0;
    /* Make the CTA text smaller */
    --cta-font-size: 26px;
    --cta-line-height: 26px;
    /* Position header CTA on full top row, reduce padding, place button next to title */
    --header-cta-width: 100%;
    --header-cta-padding-vert: 15px;
    --header-cta-flex-direction: row;
    --header-cta-button-margin: 0px;
    --header-title-cta-padding: 0;
    /* Change slideshow layout */
    --slideshow-height: auto;
    --slideshow-flex-direction: column;
    --slideshow-panel-flex: 1 0 auto;
    --slideshow-panelleft-order: 2;
    --slideshow-panelleft-padding: 0 var(--main-grid-gap) 40px 0;
    --slideshow-panelleft-width: calc(100vw - var(--main-grid-gap));
    --slideshow-panelright-order: 1;
    --slideshow-panelright-height: 320px;
    /* Make sidebar full width */
    --newseventsrecent-sidebar-columns: left / right;
    --newseventsrecent-sidebar-padding: var(--main-grid-gap);
    /* Change banner layout */
    --banner-slide-width: 100%;
    --banner-slide-padding: 25px var(--main-grid-gap) 65px var(--main-grid-gap);
    /* Make people folder list wider */
    --people-overiew-list-columns: grid-left / span 10;
    /* Update the prefooter layout again */
    --footer-prefooter-display: grid;
    --footer-prefooter-height: auto;
    --footer-address-columns: var(--main-8col-layout);
    --footer-address-padding: 0;
    --footer-image-columns: left / right;
    /* Share page takes up full width */
    --footer-share-column: grid-left / grid-right;
    /* Update footer prefooter layout*/
    --footer-address-width: 100%;
    /* Resize widgets to fit within the content width instead of the grid width */
    --widget-equalheight-width: calc((var(--main-content-width) - var(--main-grid-gap) - 1px) / 2);
    /* Search sheet takes up the whole page */
    --searsia-sheet-top: 0px;
    --searsia-close-display: block;
    /* Adjust search form width */
    --searsia-form-margin: 28px;
    --searsia-form-width: calc(100vw - 2 * var(--searsia-form-margin) - var(--navbar-height));
  }
  :root .header--hascta {
    --header-grid-rows: [top] auto [header-top] 1fr [title-above] calc(var(--header-background-height) - 2 * var(--header-sliderdots-height)) [title-below] var(--header-sliderdots-height) [bottom];
  }
}
@media (max-width: 480px) {
  :root {
    /* Reduce gutter */
    --main-grid-gap: 20px;
    /* Make the grid 6 columns wide */
    --main-grid: [left] minmax(env(safe-area-inset-left), var(--main-grid-margin)) [grid-left text-left] repeat(6, var(--main-grid-column)) [text-right grid-right] minmax(env(safe-area-inset-right), var(--main-grid-margin)) [right];
    --main-content-width: calc(100vw - 2 * var(--main-grid-gap));
    /* Hide slider dots on smaller displays */
    --header-sliderdots-display: none;
    /* Position notification contents below icons */
    --notification-padding: 56px 20px 16px;
    /* Make people folder list wider */
    --people-overiew-list-columns: grid-left / grid-right;
    /* Give footer links some margin */
    --footer-share-column: text-left / text-right;
    --footer-links-column-1: text-left / text-right;
    --footer-links-column-2: text-left / text-right;
    --footer-links-column-3: text-left / text-right;
    --footer-links-column-4: text-left / text-right;
    --footer-footerlinks-column: text-left / text-right;
    /* Make the CTA text smaller */
    --cta-font-size: 18px;
    --cta-line-height: 20px;
    /* Reduce header CTA padding */
    --header-cta-padding-vert: 10px;
    --header-cta-padding-hor: 15px;
  }
  /* Show mobile CTA button instead of desktop button, if it's available */
  .cta__button__title--mobile + .cta__button__title--default {
    --header-cta-button-display: none;
  }
  .cta__button__title--mobile {
    --header-cta-button-display: inline;
  }
}
/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
/** @short Position the element within the text area of the grid (8 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to 8-column text width
@include grid-text-width();

// Constrain to 8-column text width, but update margins (note the $ prefix!)
@include grid-text-width($margin-left: 50px, $margin-right: 50px);
*/
/** @short Position the element within the full grid area (12 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to 12-column grid width
@include grid-grid-width();

// Constrain to 12-column grid width, but update margins (note the $ prefix!)
@include grid-grid-width($margin-left: 50px, $margin-right: 50px);
*/
/** @short Position the element within the full page width (12 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to full page width
@include grid-full-width();

// Constrain to full page width, but update margins and width (note the $ prefix!)
@include grid-full-width($width: 500px, $margin-left: auto, $margin-right: auto);
*/
/** @short Apply this to the current slide in a slideshow with fading effect
*/
/** @short Apply this to the next slide to show in a slideshow with fading effect
*/
/** @short Apply this to the current slide that's being hidden in a slideshow with fading effect
*/
/** @short Styling of a header slideshow slider dot
*/
/** @short Styling of the active header slideshow slider dot
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/** @short reset a button to be a like a normal element
*/
/* NOTE: Specificity must be higher than: "html.utwente--ws2021 .foldabledeeplink__text > *" */
.twocolumnsrte--wide.twocolumnsrte--wide.twocolumnsrte--wide {
  layout: 12col;
}

.twocolumnsrte {
  display: grid;
  width: 100%;
  column-gap: var(--main-grid-gap);
  grid-column-gap: var(--main-grid-gap);
  grid-template-columns: var(--main-grid);
}

.twocolumnsrte.twocolumnsrte .foldabledeeplink,
.twocolumnsrte.twocolumnsrte .foldabledeeplink__text {
  display: block;
}

@media (min-width: 700px) {
  .twocolumnsrte__content {
    display: flex;
  }
  .twocolumnsrte__left {
    flex: 1 1 50%;
    padding-right: 6%;
  }
  .twocolumnsrte__right {
    flex: 1 1 50%;
    padding-left: 6%;
  }
}