.utapform--login--mustlink .utapform--login__action-login {
  display: none;
}

.utapform--login--mustlink .utapform--login__action-link {
  display: block;
}

#utap__resetpassword__resendsmscode {
  text-decoration: underline;
  cursor: pointer;
}

#utapform--resetpassword .wh-form__label {
  width: 320px;
  max-width: auto;
}

#utapform--login .wh-form__buttongroup {
  margin-top: 25px; /* half of the default 50px */
}

#utapform--login .wh-form__buttongroup button {
  padding-left: 35px;
  padding-right: 35px;
}

.utap--resetpassword__page {
  display: none;
}

#utapform--resetpassword[data-page=account] .utap--resetpassword__page--account {
  display: block;
}

#utapform--resetpassword[data-page=options] .utap--resetpassword__page--options {
  display: block;
}

#utapform--resetpassword[data-page=smscode] .utap--resetpassword__page--smscode {
  display: block;
}

#utapform--resetpassword[data-page=changepwd] .utap--resetpassword__page--changepwd {
  display: block;
}