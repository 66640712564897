/* NOTE: This file is also used by the rtdtype !! */



.embeddedobject--rte
{
  display: grid;
  grid-column: var(--main-full-layout) !important
}

  .embeddedobject--rte__content
, .twocolumnsrte__content
{
  z-index: 0; /* even 0 is enough to make sure it'll overlap the background image */
  grid-row: 1;
  grid-column: 1 / -1;
}





  .embeddedobject--rte--themecolor .innumbers
/*, .embeddedobject--rte--greybg .innumbers*/
{
  --ffthemecolor:   #FFFFFF !important; /* override the themecolor set inline on the innumers widget */
  --rtd-text-color: #FFFFFF;
}

.embeddedobject--rte--themecolor a
{
  color: inherit;
}

.embeddedobject--rte--themecolor .embeddedobject--rte__content .inlinecta__button
{
  background-color: #fff;
  color: var(--theme-color-cta);
  border: 2px solid #fff;
}

.embeddedobject--rte--themecolor .embeddedobject--rte__content .inlinecta__button:hover
{
  color: #fff;
  background-color: transparent;
}

  .embeddedobject--rte__content
, .twocolumnsrte__content
{
  padding-top: 95px;
  padding-bottom: 90px;
}



/* keep things a little more compact in the editor */
  .wh-rtd-editor .embeddedobject--rte__content
, .wh-rtd-editor .twocolumnsrte__content
{
  padding-top: 40px;
  padding-bottom: 40px;
}

.wh-rtd-editor .twocolumnsrte__content
{
  display: flex;
  column-gap: 6%;
}

.wh-rtd-editor .twocolumnsrte__content > *
{
  flex: 1 1 50%;
}





.embeddedobject--rte__bgimage
{
  grid-column: 1 / -1;
  grid-row: 1;

  display: grid; /* so we can align within using align-self/justify-self */
  contain: size; /* so image doesn't stretch the widget */

  overflow: hidden;
}

.embeddedobject--rte__bgimage svg
{
  align-self: center;
  justify-self: center;

  width: 100%;
  min-width: 800px;
  max-width: 1250px; /* 960px;  640px * 1.5; */
  height: auto;

  fill: #000000;
  color: #000000;
  opacity: 0.2;
}

  .embeddedobject--rte__bgimage .header__universe-grey
, .embeddedobject--rte__bgimage .header__universe-color
{
  fill: #000000;

}




/* Within a single-column RTE it's still possible to have widgets which use
   8 or 12 columns or full width */
.embeddedobject--rte__content
{
  /*//@include mixins.grid-layout();*/
  display: grid;
  column-gap: var(--main-grid-gap);
  grid-column-gap: var(--main-grid-gap); /* Backwards compatibility: firefox<61, chrome<66, safari<12 */
  grid-template-columns: var(--main-grid);
}

.wh-rtd .embeddedobject--rte__content
{
  /* we don't have --main-grid within the RTD editor context */
  grid-template-columns: 100%;
}

.embeddedobject--rte__content > *
{
  /*// @include mixins.grid-text-width();*/
  grid-column: var(--main-8col-layout);
}



.twocolumnsrte__content
{
  grid-column: var(--main-8col-layout);
}

.twocolumnsrte--wide .twocolumnsrte__content
{
  grid-column: var(--main-12col-layout);
}






/* remove margins at top and bottom, so we can fully
   control the whitespace at the top and bottom of our panel
*/
  .embeddedobject--rte__content > *:first-child
, .twocolumnsrte__left > *:first-child
, .twocolumnsrte__right > *:first-child
{
  margin-top: 0;
}

  .embeddedobject--rte__content > *:last-child
, .twocolumnsrte__left > *:last-child
, .twocolumnsrte__right > *:last-child
{
  margin-top: 0;
}

/* also target the margins in case Javascript nested our content
   into foldabledeeplinks's (mobile accordion)
*/
  .embeddedobject--rte__content > .foldabledeeplink > .foldabledeeplink__title
, .embeddedobject--rte__content > .foldabledeeplink > .foldabledeeplink__title
, .twocolumnsrte__left > .foldabledeeplink > .foldabledeeplink__title
, .twocolumnsrte__right > .foldabledeeplink > .foldabledeeplink__title
{
  margin-top: 0;
}
  .embeddedobject--rte__content > .foldabledeeplink > .foldabledeeplink__text > *:last-child
, .embeddedobject--rte__content > .foldabledeeplink > .foldabledeeplink__text > *:last-child
, .twocolumnsrte__left > .foldabledeeplink > .foldabledeeplink__text > *:last-child
, .twocolumnsrte__right > .foldabledeeplink > .foldabledeeplink__text > *:last-child
{
  margin-bottom: 0;
}

.embeddedobject--rte--themecolor
{
  background-color: var(--theme-color-cta);
  color: var(--color-white);
}
.embeddedobject--rte--themecolor .embeddedobject--rte__content a
{
  color: var(--color-white);
  text-underline-position: under;
}

.embeddedobject--rte--greybg
{
  color: var(--color-black);
  background-position: 0% 0%;
  background-image: url(../../img/paper-pattern.png);
  background-color: var(--color-grey-lighter);
}
