@charset "UTF-8";
/* Based on the WS2016 breakpoints */
.generic__filterholder {
  background-color: #FFFFFF;
  padding: 50px 0 40px 0;
}

.generic__filterholder > .main__contents--12col {
  padding: 0 0 0 0;
}

.generic__filterholder .filter__row + .filter__row {
  margin-top: 20px;
}

.generic__filterholder .filter__resetfilter {
  cursor: pointer;
  text-decoration: underline;
  display: inline-block;
  color: #5a5e62;
  font-weight: 300;
  font-size: 18px;
}

.main__contents > .generic__filterholder {
  padding-top: 28px;
  padding-bottom: 28px;
}

.page--alumnisearch .ui-autocomplete {
  width: max-content !important;
}

html.page--alumnisearch .main__contents {
  background-color: transparent;
}

.alumnisearch__filters .filter__row.limitwidth {
  max-width: 640px;
}

.alumnisearch__searchbutton {
  -webkit-flex: none;
  flex: none; /* meaning 0 0 auto */
  margin: 0 0 0 15px;
  /* ws2016 will up till 480px uses a small button */
  border-width: 3px;
  font-size: 19px;
  line-height: 24px;
}

.alumnisearch__filters input[name=query] {
  width: 100%;
}

.alumnisearch__filters input[name=organization] {
  width: 100%;
  /*  max-width: 300px;*/
}

.alumnisearch__filter--flex {
  position: relative;
  display: -webkit-flex;
  display: flex;
  width: 100%;
}

.alumnisearch__filter--query__search_in {
  flex: 0 0 auto;
  align-self: center;
  margin-left: 10px;
}

.alumnisearch__filters input[name=startyear],
.alumnisearch__filters input[name=endyear] {
  width: 100px;
}

.alumnisearch__filters .filter__row {
  display: -webkit-flex;
  display: flex;
}

.alumnisearch__filters .filter__resetfilter {
  margin-left: auto;
}

/* the organization or study on which we search */
.alumnisearch__entity {
  margin-bottom: 20px;
}

.alumnisearch__entity h2,
.alumnisearch__filters h2 {
  margin-top: 0;
  margin-bottom: 0;
}

.alumnisearch__entity h2::before,
.alumnisearch__filters h2::before {
  display: none;
}

.alumnisearch__item {
  display: -webkit-flex;
  display: flex;
  background-color: #FFFFFF; /* as peoplepage row using .widget__toggleheader */
  /* like the .foldablecontent__title */
  border-top: 1px solid #e0e0e1;
  border-bottom: 1px solid #e0e0e1;
  /*
      font-weight: 700;
      text-transform: uppercase;
      font-size: 18px;
      line-height: 18px;
  */
  padding: 16px 40px 16px 0;
  position: relative;
  /* cursor: pointer; - not fully clickable like people pages */
  z-index: 0;
  /************************************/
  /* like the .widget__toggleheader .foldablecontent__title */
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  padding: 20px;
  /************************************/
}

.alumnisearch__item + .alumnisearch__item {
  border-top: 0;
}

.alumnisearch__item__photo {
  width: 60px;
  height: 60px;
  margin-right: 20px;
  /* like .widget__contactperson__photo */
  -ms-flex-negative: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  /**************************************/
  -webkit-align-self: flex-start; /* FIXME: is this correct? */
  align-self: flex-start;
}

.alumnisearch__item__photo:empty::before {
  content: "";
  display: block;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  /*  background-color: #E6E6E6;*/
  background: transparent url("../../web/img/peoplepage-placeholder.jpg");
  background-size: cover;
}

.alumnisearch__item__photo img {
  display: block;
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.alumnisearch__item__meta {
  /* like .widget__contactperson__meta */
  -ms-flex-positive: 1;
  -webkit-flex-grow: 1;
  webkit-grow: 1;
}

/*.alumnisearch__item__fullname*/
.alumnisearch__item__firstline {
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  position: relative;
  text-transform: uppercase;
}

a.alumnisearch__item__fullname {
  color: #000000;
  text-decoration: none;
}

a.alumnisearch__item__fullname:hover {
  text-decoration: underline;
}

/*
.alumnisearch__item__firstline::first-line
{
  color: #F00;
}
*/
.alumnisearch__item__location {
  font-weight: 300;
}

.alumnisearch__item__location a {
  text-decoration: none;
}

.alumnisearch__item__location a:hover {
  text-decoration: underline;
}

.alumnisearch__item__city {
  white-space: nowrap;
}

.alumnisearch__item__country {
  white-space: nowrap;
}

.alumnisearch__item__headline {
  color: #888888;
  margin-top: -2px;
}

.alumnisearch__item__firstline + .alumnisearch__item__edus {
  margin-top: 8px;
}

.alumnisearch__item__headline + .alumnisearch__item__edus {
  margin-top: 5px;
}

/*
@media (min-width: 800px)
{
*/
.alumnisearch__item__memberships {
  margin-top: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: -5px;
}

.alumnisearch__item__memberships a {
  margin-right: 15px;
  margin-bottom: 5px;
}

.alumnisearch__item__memberships a:last-child {
  margin-right: 0;
}

/*
}

@media (max-width: 799px)
{
  .alumnisearch__item__location
  {
    display: block;
  }

  .alumnisearch__item__memberships
  {
    display: block;
  }

  .alumnisearch__item__memberships a
  {
    display: table;
  }
}
*/
.alumnisearch__item__memberships {
  margin-top: 15px;
  margin-right: -15px;
}

.alumnisearch__item__memberships a {
  text-decoration: none;
}

.alumnisearch__item__memberships .boardmembericon {
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -2px;
}

a.alumnisearch__item__edu__programme {
  text-decoration: none;
}

a.alumnisearch__item__edu__programme:hover {
  text-decoration: underline;
}

.alumnisearch__item__edu__when {
  color: #666666;
}

.alumnisearch__item__arrow {
  text-decoration: none;
}

/*
.visually-hidden {
    display: block;
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
*/
/* content for the toggleheader of the contactperson
*/
.widget__contactperson__photo {
  -ms-flex-negative: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}

.widget__contactperson__photo > img,
.widget__contactperson__placeholder {
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.widget__contactperson__placeholder {
  background-color: #F0F0F0;
  background: transparent url("../../web/img/peoplepage-placeholder.jpg");
}

.widget__contactperson__meta {
  -ms-flex-positive: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}

/*

iPhone SE - virtual width 640
iPhone 7  - virtual width 750px

*/
@media (max-width: 750px) {
  .alumnisearch__item__arrow {
    display: none;
  }
  .alumnisearch__item__location {
    margin-top: 3px;
    display: block;
    font-size: 16px;
  }
  .alumnisearch__item__headline {
    font-size: 12px;
    line-height: 125%;
    margin-top: 2px;
  }
  .alumnisearch__item__headline + .alumnisearch__item__edus {
    margin-top: 8px;
  }
  .alumnisearch__item__edus {
    font-size: 13px;
  }
  .alumnisearch__item__memberships {
    margin-right: -7px;
  }
  .alumnisearch__item__memberships .utap__tag {
    margin-right: 7px;
  }
}
.filter__studyperiod {
  flex: 1;
}

.filter__studyperiod.filter__studyperiod input {
  flex-grow: 0; /* FIX WS2016 feeling the need to set this to 1.. flex-grow will override the set width */
}

.alumnisearch__resultscount {
  margin-top: 20px;
}

.alumnisearch__listview {
  margin-top: 20px;
}

@media (max-width: 480px) {
  .alumnisearch__filters .filter__row--studyperiod {
    display: block;
  }
  .alumnisearch__filters .filter__studyperiod__line2 {
    display: block;
  }
  .alumnisearch__filters .filter__resetfilter {
    margin-top: 20px;
    display: block;
  }
  .alumnisearch__resultscount {
    margin-top: 10px;
  }
  .alumnisearch__listview {
    margin-top: 10px;
  }
}
@media (min-width: 481px) {
  .filter__studyperiod__line2 {
    display: -webkit-flex;
    display: flex;
    align-items: center;
  }
}
@media (min-width: 751px) {
  .filter__row--studyperiod {
    align-items: baseline;
  }
  /* add a seperator */
  .alumnisearch__item__fullname + .alumnisearch__item__location::before {
    display: inline-block;
    content: "•";
    margin-left: 8px;
    margin-right: 8px;
    font-size: 14px;
    position: relative;
    top: -1px;
  }
}