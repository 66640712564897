/* Based on the WS2016 breakpoints */
.alumni__personal__topeditprofilebutton {
  float: right;
  margin-right: 0 !important;
}

/*@media (min-width: 750px)*/
@media (min-width: 750px) {
  .mobileonly {
    display: none;
  }
}
.alumni__feedlink,
.alumni__sociallinks {
  display: block;
  margin-top: 20px;
}

/* if the jobdescription + company doesn't fit on a single line,
   try to get line 1 to be the job and line 2 to be the company
*/
.career_jobdescription .at {
  white-space: nowrap;
}

/*
@media (max-width: 960px)
{
  .alumnisearch__filters
  {
    padding-left: 20px;
    padding-right: 20px;
  }
}
*/
@media (max-width: 749px) {
  .alumni__personal__grid__photo {
    margin-top: 20px;
    display: table; /* shrinkwrap */
  }
  .alumni__personal__grid__photo .cta__buttonbar--vertical .alumnicta__button--small {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 750px) {
  .alumni__personal__grid {
    display: -webkit-flex;
    display: flex;
    width: 100%;
  }
  .alumni__personal__grid__meta {
    -webkit-flex: 1 1 0px;
    flex: 1 1 0px;
  }
  .alumni__personal__grid__image {
    /* fixed size */
    -webkit-flex: none;
    flex: none;
    margin-left: 20px;
  }
  .alumni__personal__grid__photo {
    margin-left: 20px;
  }
}
.alumnitable {
  width: 100%;
}

.alumnitable td {
  width: 65%;
  padding-left: 10px;
}

.alumnitable td:first-child {
  width: 35%;
}

.alumnitable--cleanlook {
  border-collapse: collapse;
}

.alumnitable--cleanlook td {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 0;
  padding-right: 5px;
  vertical-align: baseline;
}

.alumnitable--cleanlook td:last-child {
  padding-right: 0;
}

#alumniportal_profileform #membershipsoverview__items tr:hover {
  background-color: #F7F7F7;
}

.education__date {
  white-space: nowrap;
}

.alumni__linkedin_headline {
  font-weight: normal;
}

.career__date {
  white-space: nowrap;
}

.membership__date {
  white-space: nowrap;
}

.membership__description {
  margin-top: -7px;
  font-size: 85%;
}

.alumni__sociallinks .utapicon {
  margin-right: 10px;
}

.alumni__sociallinks + .whfeed {
  margin-top: 30px;
}