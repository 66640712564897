/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
/** @short Position the element within the text area of the grid (8 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to 8-column text width
@include grid-text-width();

// Constrain to 8-column text width, but update margins (note the $ prefix!)
@include grid-text-width($margin-left: 50px, $margin-right: 50px);
*/
/** @short Position the element within the full grid area (12 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to 12-column grid width
@include grid-grid-width();

// Constrain to 12-column grid width, but update margins (note the $ prefix!)
@include grid-grid-width($margin-left: 50px, $margin-right: 50px);
*/
/** @short Position the element within the full page width (12 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to full page width
@include grid-full-width();

// Constrain to full page width, but update margins and width (note the $ prefix!)
@include grid-full-width($width: 500px, $margin-left: auto, $margin-right: auto);
*/
/** @short Apply this to the current slide in a slideshow with fading effect
*/
/** @short Apply this to the next slide to show in a slideshow with fading effect
*/
/** @short Apply this to the current slide that's being hidden in a slideshow with fading effect
*/
/** @short Styling of a header slideshow slider dot
*/
/** @short Styling of the active header slideshow slider dot
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/** @short reset a button to be a like a normal element
*/
/*  .page--alumniprofile h2*/
.page--alumnieditprofile h2 {
  padding-top: 13px;
  padding-bottom: 11px;
  /* note: same color as text inputs */
  border-top: 1px solid #CBCBCB;
  border-bottom: 1px solid #CBCBCB;
}

.utap__profileform__infopanel {
  margin-top: 20px;
  margin-bottom: 20px;
}

.profileform__jobs {
  margin-top: 30px;
}

.alumniportal_editjobform.jobiscurrent .form--editjob__until,
.alumniportal_editjobform.jobiscurrent input[name=endyear] {
  visibility: hidden;
}

.form--editjob__until {
  margin-left: 10px;
  margin-right: 10px;
}

.form--editjob__iscurrent {
  margin-top: 30px;
  display: flex;
  align-items: center;
}

.form--editjob__iscurrent input + label {
  margin-right: 10px;
}

.utap__profileform__privacysettings {
  margin-top: 30px;
}

.utap__profileform__privacysettings .wh-form__label {
  display: none;
}

.alumni__personal__grid__photo--edit .wh-form__label {
  display: none;
}

.alumni__personal__grid__photo.photofromsocial .alumni__personal__grid__photo--upload {
  display: none;
}

.alumni__personal__grid__photo.photofromsocial .cta__buttonbar--vertical {
  display: none;
}

.alumni__personal__grid__photo--upload .wh-form__imgedit {
  max-width: none;
  max-height: none;
}

#alumniportal_profileform .main__contents:first-child {
  padding-top: 50px; /* the same as .wh-form__questiongroup + .wh-form__questiongroup */
  padding-bottom: 50px;
}

#profile-wrd_userpicture {
  width: 200px;
  height: 200px;
}

.utap__profileform__profileurl {
  -webkit-flex: 1;
  flex: 1;
}

/*
.utap__profileform__profileurl:empty
{
  display: none;
}
*/
.wh-form__fieldgroup--socialconnect .utap__connect {
  display: inline-block;
  margin: 0; /* WS2016 uses a nasty default margin on .cta__button */
}

.socialconnect--connected .utap__connect {
  display: none;
}

.wh-form__fieldgroup--socialconnect:not(.socialconnect--connected) .utap__disconnect {
  display: none;
}

.wh-form__buttonbar {
  text-align: right;
  margin-top: 40px;
}

.alumniformfieldgroup--addressfield:not(.alumniformfieldgroup--addressfield--edit) .alumniformfieldgroup--addressfield__editpanel {
  display: none;
}

.alumniformfieldgroup--addressfield--edit .alumniformfieldgroup--addressfield__viewpanel {
  display: none;
}

/* 2x the same class for a higher priority */
.alumniformfieldgroup--addressfield__viewpanel.alumniformfieldgroup--addressfield__viewpanel textarea {
  -webkit-flex-basis: 100%;
  flex-basis: 100%;
}

.alumniformfieldgroup--addressfield__buttonbar {
  text-align: right;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
}

#utap__profileform__positions {
  margin-top: 20px;
}

.profileform__addbutton {
  margin-top: 20px;
  color: #888888;
}

.profileform__addbutton:active,
.profileform__addbutton:hover {
  color: #000000;
  cursor: pointer;
}

.profileform__addbutton .utap_icon {
  display: inline-block;
  margin-top: -2px;
  margin-right: 10px;
}

.profileform__addbutton > * {
  vertical-align: middle;
}

.utap__profileform__memberships__addpanel td {
  padding-top: 6px;
  padding-bottom: 6px;
}

/* all clones will have this id removed and will be visible */
#cloneable_membershiprow {
  display: none;
}

/* the spinner takes away much room and lookes cluttery
   but we want to use type=number for mobile devices/virtual keyboards
*/
input[type=number].utap--nospinner::-webkit-outer-spin-button,
input[type=number].utap--nospinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number].utap--nospinner {
  -moz-appearance: textfield;
}

.membership__typeedit {
  max-width: 200px;
}

.addmembership_startyear {
  margin-right: 5px;
}

.addmembership_endyear {
  margin-left: 5px;
}

.membership__titleedit__organization,
.addmembership_description {
  display: block;
  width: 100%;
}

.addmembership_orgname.valid.valid {
  color: #080;
}

.addmembership_orgname.invalid.invalid {
  color: #A00;
}

#membershipsoverview {
  width: auto;
  min-width: 100%;
  margin-left: -8px;
  margin-right: -8px;
}

#membershipsoverview td:first-child {
  padding-left: 8px;
}

.utap__profileform__memberships__removebutton {
  cursor: pointer;
  color: #CBCBCB;
  font-size: 20px;
  display: inline-block;
  line-height: 25px;
  padding-left: 8px;
  padding-right: 8px;
}

.utap__profileform__memberships__removebutton:hover,
.utap__profileform__memberships__removebutton:active {
  color: #000000;
}

#membership-suggest-container {
  max-width: 300px;
}

#membership-suggest-container li {
  line-height: 21px; /* instead of 25px */
  padding-top: 4px;
  padding-bottom: 4px;
}

.page--alumnieditprofile .ui-autocomplete {
  max-width: 300px;
}

.alumniportal_profileform .wh-form__label,
.alumniportal_membershipform .wh-form__label {
  width: 185px;
}

.alumniportal_editjobform .wh-form__label {
  width: 140px;
}

#alumniportal_profileform__floatingsubmitbar {
  background-color: #ffffff;
  display: grid;
  width: 100%;
  column-gap: var(--main-grid-gap);
  grid-column-gap: var(--main-grid-gap);
  grid-template-columns: var(--main-grid);
  padding-bottom: 10px;
}
#alumniportal_profileform__floatingsubmitbar > .wh-form__buttonbar {
  grid-column: var(--main-8col-layout);
}

#alumniportal_profileform__submitbar {
  padding-bottom: 10px;
}

#alumniportal_profileform__floatingsubmitbar .wh-form__buttonbar,
#alumniportal_profileform__submitbar .wh-form__buttonbar {
  /* be sure NOT to override left/right margin or paddings.
     ws2016 always has some padding (for min white space) and left/right margin auto
     for centering content
  */
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.page--alumnieditprofile .wh-form__fieldgroup--socialconnect .wh-form__fieldgroup--checkbox .wh-form__label {
  order: 2;
  max-width: none;
  width: auto;
}

.page--alumnieditprofile .wh-form__fieldgroup--socialconnect .wh-form__fieldgroup--checkbox .wh-form__fields {
  -webkit-flex: none;
  flex: none;
}

/*
Linked Accounts
*/
.utap__profileform__profileurl {
  width: 0; /* so we grow to fit available room instead of to make our content fit */
  -webkit-flex: 1;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.utap__disconnect {
  margin: 0 0 0 15px;
}

.dialog--editmembership {
  width: 600px;
  max-width: calc(100% - 40px);
}

.addmembership_startyear {
  width: 100px;
}

.addmembership_endyear {
  width: 100px;
}

.dialog--editmembership textarea.addmembership_description {
  height: 79px;
}

.addmembership_missingclublink {
  font-size: 85%;
  color: #999999;
}

.alumniportal_profileform__sociallinks .wh-form__fieldgroup .utapicon {
  margin-right: 10px;
  align-self: center;
}

.wh-form__fieldgroup--socialconnect .wh-form__label {
  flex-shrink: 1;
}

@media (max-width: 505px) {
  .alumniportal_profileform__sociallinks .wh-form__fieldgroup {
    display: block;
  }
  .alumniportal_profileform__sociallinks .wh-form__fieldgroup .utapicon,
  .alumniportal_profileform__sociallinks .wh-form__fieldgroup .wh-form__label {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 5px;
  }
}
.wh-form__questiongroup .profileform__jobs tr {
  display: flex;
  width: 100%;
}

.wh-form__questiongroup .profileform__jobs td.career__date {
  flex: 0 0 185px;
  padding-right: 10px;
}

.wh-form__questiongroup .profileform__jobs td.career__jobdescription {
  flex: 1 0 0px;
}

.wh-form__questiongroup .profileform__jobs td.career__removetd {
  flex: 0 0 0;
}

select:invalid + .ut-pulldown {
  border: 1px solid #F00;
}