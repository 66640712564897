.utap-grid__contents
{
  position: relative;
}


@media (min-width: 600px)
{
  .utap-grid--masonry .utap-grid__contents
  {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }

  /*
  Get a preview of the .utap-grid__contents which is as near as possible to masonry without Javascript.
  */
  .wh-rtd-editor .utap-grid--masonry .utap-grid__contents
  {
    max-width: none;

    position: relative;
    padding: 10px;
  /*
    -webkit-columns: 3;
    -moz-columns: 3;
    columns: 3;
  */
    -webkit-column-gap: 20px;
    -moz-column-gap: 20px;
    column-gap: 20px;

    margin: 0 0 0px 0;
    overflow: hidden;

    border-top: 1px solid transparent; /* keep margins inside */
  }

  .wh-rtd-editor .utap-grid--masonry.utap-grid--cols2 .utap-grid__contents
  {
    -webkit-columns: 2;
    -moz-columns: 2;
    columns: 2;
  }

  .wh-rtd-editor .utap-grid--masonry.utap-grid--cols3 .utap-grid__contents
  {
    -webkit-columns: 3;
    -moz-columns: 3;
    columns: 3;
  }
}




.utap-grid--masonry
{
  -moz-column-gap: 20px; /* all FF versions so far (at least up till 49) */
  -webkit-column-gap: 20px; /* webkit prefix Chrome < 50, SF < 9, iOS < 9.2 */
  column-gap: 20px;
}



/* Masonry with equal height rows */
.utap-grid--equalheight
{
  /* !! FIXME: get rid of this. for now we need it to prevent
               scrollbars due to the margin-right: -20px; workaround
               for the flex grid
  */
/*
  overflow: hidden;
*/
}

.utap-grid--equalheight .utap-grid__contents
{
  display: -ms-flexbox; /* IE10 */
  display: -webkit-flex; /* needed for iOS < 9.2 and SF < 9 */
  display: flex;

  -webkit-flex-flow: row wrap; /* flex-wrap: wrap */
  flex-flow: row wrap; /* flex-wrap: wrap */

  width: calc(100% + 20px);
  margin-bottom: -20px;
}

/* negative margins in a .wh-rtd-embeddedobject (in the RTE) causes scrollbars) */
.wh-rtd-editor .utap-grid--equalheight .utap-grid__contents
{
  margin-bottom: 0;
}

.utap-grid--equalheight .utap-grid__contents .widget
{
  position: relative;
  flex-basis: calc(50% - 20px);
  margin-right: 20px;
  margin-bottom: 20px;
}
.utap-grid--equalheight .utap-grid__contents.utap-grid__contents--extraspacing .widget
{
  margin-right: 40px;
}

.utap-grid--equalheight.utap-grid--cols2 .utap-grid__contents .widget
{
  /* flex-basis: calc(50% - 20px); */

  /* Workaround:
     - IE10/11: don't use flex-basis because it incorrectly assumes to always have box-sizing: content-box;
     - IE10: calc() not supported, even in longhand flex-basis declarations
  */
  flex-basis: auto;
  width: calc(50% - 20px);
}
.utap-grid--equalheight.utap-grid--cols2 .utap-grid__contents--extraspacing .widget
{
  /* flex-basis: calc(50% - 40px); */
  flex-basis: auto;
  width: calc(50% - 40px);
}

.utap-grid--equalheight.utap-grid--cols3 .utap-grid__contents .widget
{
  /* flex-basis: calc(33.33% - 20px); */
  flex-basis: auto;
  width: calc(33.33% - 20px);
}
.utap-grid--equalheight.utap-grid--cols3 .utap-grid__contents--extraspacing .widget
{
  /* flex-basis: calc(33.33% - 40px); */
  flex-basis: auto;
  width: calc(33.33% - 40px);
}

.utap-grid--equalheight.utap-grid--cols4 .utap-grid__contents .widget
{
  /* flex-basis: calc(25% - 20px); */
  flex-basis: auto;
  width: calc(25% - 20px);
}
.utap-grid--equalheight.utap-grid--cols4 .utap-grid__contents--extraspacing .widget
{
  /* flex-basis: calc(25% - 40px); */
  flex-basis: auto;
  width: calc(25% - 40px);
}









.wh-rtd-editor .utap-grid--masonry .utap-grid__contents .widget
{
  margin: 0;
}

.wh-rtd-editor .utap-grid--masonry .utap-grid__contents .widget-rtdwrapper
{
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
  padding: 0 0 20px 0; /* get the 20px margin again and reset the margin we used on .wh-rtd-widget */
}

.wh-rtd-editor .utap-grid--masonry .utap-grid__contents .widget-rtdwrapper::after
{
  /* else floats which stick a little outside our wrapper get send to the next column by Webkit */
  content: " ";
  display: block;
  clear: both;
}

.wh-rtd-editor .utap-grid--masonry .widget
{
  -webkit-column-break-inside:avoid;
  -moz-column-break-inside:avoid;
  -o-column-break-inside:avoid;
  -ms-column-break-inside:avoid;
  column-break-inside:avoid;

  page-break-inside: avoid; /* Firefox 20+ ? */
  break-inside: avoid-column;
}

