.alumni__personal__grid__photo--linkedin > img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  /* M */
  border-radius: 50%;
  border: 1px solid #CBCBCB;
  width: 200px;
  height: 200px;
}